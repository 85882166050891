import { GraphQLResult } from '@aws-amplify/api/lib/types';
import { API, graphqlOperation } from 'aws-amplify';
import { listEmployees } from '../graphql/queries';
import { Employee } from '../models';

interface Create<G> {
  input: G;
}

export async function mutate<T>(mutation: string, data: T): Promise<GraphQLResult> {
  return new Promise((resolve, reject) => {
    const variables: Create<T> = {
      input: {
        ...data,
      },
    };
    (API.graphql(graphqlOperation(mutation, variables)) as Promise<GraphQLResult>).then(resolve).catch(reject);
  });
}

export async function get<T>(query: string, id: string): Promise<GraphQLResult<T>> {
  return new Promise((resolve, reject) => {
    (API.graphql(graphqlOperation(query, { id: id })) as Promise<GraphQLResult<T>>).then(resolve).catch(reject);
  });
}

export async function list<T>(query: string, variables?: Record<string, unknown>): Promise<GraphQLResult<T>> {
  return new Promise((resolve, reject) => {
    (API.graphql(graphqlOperation(query, variables)) as Promise<GraphQLResult<T>>).then(resolve).catch(reject);
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function shallowRemoveEmptyStrings<T = any>(input: T): T {
  const keys = Object.keys(input);
  for (let i = 0; i < keys.length; i++) {
    if ((input as any)[keys[i]] === '') {
      (input as any)[keys[i]] = null;
    }
  }
  return input;
}

export const listActiveEmployeesByOrganisationId = async (organisationId: string): Promise<Employee[]> => {
  const variables = {
    filter: {
      organisationId: { eq: organisationId },
      deleted: { ne: true },
    },
    limit: 500,
  };
  return await getAllEmployees(listEmployees, variables);
};

export const listActiveChairpersonsByOrganisationId = async (organisationId: string): Promise<Employee[]> => {
  const variables = {
    filter: {
      organisationId: { eq: organisationId },
      deleted: { ne: true },
      approvedChairPerson: { eq: true},
    },
    limit: 502,
  };
  return await getAllChairpersons(listEmployees, variables);
};

const getAllChairpersons = async (query: any, variables: any, nextToken: string | null = null): Promise<Employee[]> => {
  if (nextToken) {
    variables.nextToken = nextToken;
  }

  // console.log(query)
  // console.log(variables)

  const res = await list<{ listEmployees?: { items: Employee[]; nextToken?: string } }>(query, variables);

  const employees = res.data?.listEmployees?.items || [];
  const newNextToken = res.data?.listEmployees?.nextToken;

  if (newNextToken) {
    return employees.concat(await getAllChairpersons(query, variables, newNextToken));
  } else {
    console.log(employees)
    return employees;
  }
};


const getAllEmployees = async (query: any, variables: any, nextToken: string | null = null): Promise<Employee[]> => {
  if (nextToken) {
    variables.nextToken = nextToken;
  }

  const res = await list<{ listEmployees?: { items: Employee[]; nextToken?: string } }>(query, variables);

  const employees = res.data?.listEmployees?.items || [];
  const newNextToken = res.data?.listEmployees?.nextToken;

  if (newNextToken) {
    return employees.concat(await getAllEmployees(query, variables, newNextToken));
  } else {
    return employees;
  }
};

export const listAllEmployeesByOrganisationId = async (organisationId: string, nextToken?: string): Promise<Employee[]> => {
  const variables = { 
    filter: { organisationId: { eq: organisationId } }, 
    limit: 500, 
    nextToken: nextToken 
  };

  const res = await list(listEmployees, variables);

  const employees: Employee[] | undefined = res.data && (res.data as any).listEmployees?.items;
  if (!employees) {
    throw new Error('Invalid response');
  }

  // If a nextToken is present in the response, there are more records to fetch
  const newNextToken = res.data && (res.data as any).listEmployees?.nextToken;

  if (newNextToken) {
    // Merge the fetched employees with the employees from the next page
    return employees.concat(await listAllEmployeesByOrganisationId(organisationId, newNextToken));
  }

  // If no nextToken is present, all records have been fetched
  return employees;
};
