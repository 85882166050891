import React, {useCallback, useContext, useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router';
import { convertToFlowableVariables, updateProcessInstanceVariables } from '../../utils/flowable/flowable-utils';
import { CaseProgressBar } from '../../components/caseProgressBar/caseProgressBar';
import { CaseStatusBar } from '../../components/caseStatusBar/caseStatusBar';
import './workflowContainer.styles.scss';
import { CaseType, LogoPosition, TemplateType } from '../../API';
import { CachedFormData, CaseData, CaseOwnerDetails, filterFormData, renderComponentByFormKey } from './workflow-utils';
import UserInfoPopup from '../../components/userInfoPopup/UserInfoPopup.component';
import { UserContext, UserContextProps } from '../../App';
import TopBarComponent from '../../components/topBar/TopBar.component';
import Loader from '../../components/loader/Loader';
import { useErrorHandler } from '../../utils/notification-utils';
import DocumentEditor, { DocumentEditorProps } from '../DocumentEditor/DocumentEditor';
import { FormikValues } from 'formik';
import AdhocContainer from '../../components/AdhocContainer/AdhocContainer';
import TaskContainer from '../../components/taskContainer/TaskContainer';
import { Employee, Organisation } from '../../models';
import { FlowableHistoricTask, FlowableTask, FlowableVariable } from '../../utils/flowable/flowable-types';
import { completeFlowableTask, getWorkflowContainerData } from '../../utils/flowable/flowable-api-utils';
import { taskConfigs } from '../../configs/task-configs/taskConfigs';
import { TaskConfig } from '../../configs/task-configs/task-config-types';
import { useWhyDidYouUpdate } from '../../hooks/whyDidYouUpdate';
import { startNewDocumentFlow } from '../DocumentEditor/document-utils';
import { documentConfigs } from '../../configs/document-configs/document-configs';

export interface ApiData {
  taskData: TaskData | null;
  metaData: MetaData;
  currentDummyFormKey: string | null;
}

export interface TaskData {
  currentTask: FlowableTask | FlowableHistoricTask;
  formData: any | null;
  documentId: string;
  caseData: CaseData;
  isDocumentReadOnly: boolean;
}

export interface MetaData {
  employee: Employee;
  caseStatus: string;
  templateLogo: { imageUrl: string; position: LogoPosition } | undefined;
  currentDummyFormKey: string | null;
  organisation: Organisation;
  caseOwnerDetails: CaseOwnerDetails;
  isSuspended: boolean;
  caseType: CaseType;
  progressBarData: {
    steps: string[];
    stepIndex: number;
  } | null;
}

export interface WorkflowContainerState {
  taskData: TaskData | null;
  metaData: MetaData | null;
  showFirstTaskWarning: boolean;
  disableBackButton: boolean;
  disableFooter: boolean;
  hasJustCompletedATask: boolean;
  documentEditorProps: DocumentEditorProps | null;
  lastFormValues: Record<string, any>;
  loading: boolean;
  isTaskReadOnly: boolean;
  pathVariableName: string;
  taskConfig: TaskConfig | null;
}

export const WorkflowContainer: React.FC = () => {
  const history = useHistory();
  const [state, setState] = useState<WorkflowContainerState>({
    taskData: null,
    metaData: null,
    showFirstTaskWarning: false,
    disableBackButton: false,
    hasJustCompletedATask: false,
    loading: false,
    documentEditorProps: null,
    lastFormValues: {},
    isTaskReadOnly: false,
    pathVariableName: 'path',
    taskConfig: null,
    disableFooter: false,
  });

  const { masterProcessInstanceId, subprocessInstanceId } = useParams<{
    masterProcessInstanceId: string;
    subprocessInstanceId: string;
  }>();

  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;

  const handleError = useErrorHandler();

  const loadCaseData = useCallback(async (masterProcessInstanceId: string): Promise<void> => {
    if (currentUser) {
      setState((oldState: WorkflowContainerState) => ({
        ...oldState,
        loading: true,
      }));
      getWorkflowContainerData(masterProcessInstanceId, currentUser.id, subprocessInstanceId)
        .then(res => {
          const data: ApiData = res.data;
          let showFirstTaskWarning = false;
          let disableBackButton = false;
          let isTaskReadOnly = false;
          let pathVariableName = 'path';
          let taskConfig: TaskConfig | null = null;
          if (data.taskData) {
            if (data.taskData.currentTask?.taskDefinitionKey) {
              taskConfig = taskConfigs[data.taskData.currentTask.taskDefinitionKey];
              showFirstTaskWarning = !!taskConfig?.showFirstTaskWarning;
              disableBackButton = !!taskConfig?.disableBackButton;
              isTaskReadOnly = !!taskConfig?.readOnly;
              pathVariableName = taskConfig?.pathVariableName ? taskConfig.pathVariableName : 'path';
              console.log('taskDefinitionKey', data.taskData.currentTask?.taskDefinitionKey);
              console.log('taskConfig', taskConfig);
            }

            const cacheName = `zz-cached-${data.taskData?.currentTask.id}`;
            const cachedDataVar = data.taskData.currentTask.variables.find(i => i.name === cacheName);
            if (cachedDataVar && typeof cachedDataVar.value === 'string') {
              const vars = JSON.parse(cachedDataVar.value) as { data: Record<string, unknown> };
              Object.entries(vars.data).forEach(([key, value]) => {
                if (data.taskData) {
                  data.taskData.caseData[key] = value;
                }
              });
              if (data.taskData) data.taskData.caseData[cacheName] = null;
            }
          }
          console.log('*************************setting state with pathVariableName:', pathVariableName);
          setState((oldState: WorkflowContainerState) => ({
            ...oldState,
            taskData: data.taskData ? data.taskData : null,
            metaData: data.metaData,
            showFirstTaskWarning: showFirstTaskWarning,
            disableBackButton: disableBackButton,
            currentDummyFormKey: data.currentDummyFormKey,
            loading: false,
            isTaskReadOnly: isTaskReadOnly,
            pathVariableName: pathVariableName,
            taskConfig: taskConfig,
            disableFooter: !!subprocessInstanceId,
          }));
        })
        .catch(error => {
          setState(oldState => ({
            ...oldState,
            loading: false,
          }));
          handleError(error);
        });
    } else {
      handleError(new Error('No current user'));
    }
  }, []);

  // TODO:
  const loadSuspensionStatus = (employeeId: string) => {
    // getCurrentSuspensionStatus(employeeId)
    //   .then(res => {
    //     setState(oldState => ({ ...oldState, isSuspended: res }));
    //   })
    //   .catch(error => handleError(error));
  };

  const updateVariables = async (variables: FlowableVariable[]): Promise<void> => {
    if (masterProcessInstanceId) {
      setState(oldState => ({ ...oldState, loading: true }));
      await updateProcessInstanceVariables(masterProcessInstanceId, variables).then(async () =>
        loadCaseData(masterProcessInstanceId),
      );
    } else {
      const error = new Error('Error: current processInstanceId');
      setState(oldState => ({ ...oldState, loading: false }));
      handleError(error);
    }
  };

  const updateVariablesCurrentProcess = async (variables: FlowableVariable[]): Promise<void> => {
    if (state.taskData?.currentTask.processInstanceId) {
      setState(oldState => ({ ...oldState, loading: true }));
      await updateProcessInstanceVariables(state.taskData?.currentTask.processInstanceId, variables).then(() => {
        setState(oldState => ({ ...oldState, loading: false }));
        //   loadCaseData(masterProcessInstanceId),
      });
    } else {
      const error = new Error('Error: current processInstanceId');
      setState(oldState => ({ ...oldState, loading: false }));
      handleError(error);
    }
  };

  const completeTask = async (variables?: FlowableVariable[]): Promise<void> => {
    if (!state.taskData) {
      throw new Error('No Task Data');
    }

    const taskData: TaskData = state.taskData;

    setState(oldState => ({ ...oldState, loading: true }));
    if (taskData.currentTask && masterProcessInstanceId) {
      if (variables) {
        if (taskData.currentTask) {
          completeFlowableTask(taskData.currentTask.id, currentUser ? currentUser.id : null, variables)
            .then(() => {
              setState(oldState => ({ ...oldState, hasJustCompletedATask: true }));
              loadCaseData(masterProcessInstanceId);
            })
            .catch(error => {
              setState(oldState => ({ ...oldState, loading: false }));
              handleError(error);
            });
        }
      } else {
        completeFlowableTask(taskData.currentTask.id, currentUser ? currentUser.id : null)
          .then(() => {
            setState(oldState => ({ ...oldState, hasJustCompletedATask: true }));
            if (masterProcessInstanceId) {
              loadCaseData(masterProcessInstanceId);
            }
          })
          .catch(error => {
            setState(oldState => ({ ...oldState, loading: false }));
            handleError(error);
          });
      }
    } else {
      const error = new Error('Error: no currentTask or processInstanceId');
      handleError(error);
    }
  };

  const saveFormData = async (formData: FormikValues): Promise<void> => {
    return new Promise((resolve, reject) => {
      const cachedFormData: CachedFormData = {
        taskDefinitionKey: state.taskData?.currentTask?.taskDefinitionKey
          ? state.taskData?.currentTask?.taskDefinitionKey
          : '',
        data: formData,
      };
      const cacheName = `zz-cached-${state.taskData?.currentTask.id}`;
      const vars: FlowableVariable[] = [{ name: cacheName, value: JSON.stringify(cachedFormData) }];
      updateVariablesCurrentProcess(vars)
        .then(() => resolve())
        .catch(error => reject(error));
    });
  };

  const onSaveAndClose = async (formData?: FormikValues): Promise<void> => {
    if (formData) {
      saveFormData(formData)
        .then(() => {
          if (state.metaData?.employee) history.push('/create-employee/' + state.metaData.employee?.id);
        })
        .catch(error => handleError(error));
    } else {
      if (state.metaData) history.push('/create-employee/' + state.metaData.employee?.id);
    }
  };

  const onNext = async (formData?: FormikValues, closeCase?: boolean): Promise<void> => {
    if (state.isTaskReadOnly) {
      formData = undefined;
    }
    if (state.taskData) {
      let vars: FlowableVariable[] = [
        { name: state.pathVariableName ?? 'path', value: 'next' },
        {
          name: 'lastTaskDefinitionKey',
          value: state.taskData.currentTask?.taskDefinitionKey ? state.taskData.currentTask.taskDefinitionKey : '',
        },
      ];
      const cacheVariables = state.taskData.currentTask.variables.filter(f => f.name.startsWith('zz-cached'));
      cacheVariables.forEach(v => {
        vars.push({
          name: v.name,
          value: null,
        });
      });
      if (formData && !state.isTaskReadOnly) {
        vars = vars.concat(convertToFlowableVariables(formData));
      }
      completeTask(vars);
    } else handleError(new Error('No path variable'));
  };

  const goBack = async (): Promise<void> => {
    completeTask([{ name: state.pathVariableName ?? 'path', value: 'back' }]);
  };

  const clearDocument = (): void => {
    setState(prevState => ({ ...prevState, documentEditorProps: null }));
  };

  const getFlowableVariables = (inputValues: Record<string, any>): FlowableVariable[] => {
    const values = { ...inputValues };
    if (values.incidents && typeof values.incidents !== 'string') values.incidents = JSON.stringify(values.incidents);
    if (values.periodOfUnpaidSuspension) {
      values.periodOfUnpaidSuspension = JSON.stringify(values.periodOfUnpaidSuspension);
    }
    if (values.pipDiscussionAttendees !== undefined && typeof values.pipDiscussionAttendees !== 'string') {
      values.pipDiscussionAttendees = JSON.stringify(values.pipDiscussionAttendees);
    }
    if (values.performanceShortfalls !== undefined && typeof values.performanceShortfalls !== 'string') {
      values.performanceShortfalls = JSON.stringify(values.performanceShortfalls);
    }
    if (values.pipDiscussionDocuments !== undefined && typeof values.pipDiscussionDocuments !== 'string') {
      values.pipDiscussionDocuments = JSON.stringify(values.pipDiscussionDocuments);
    }
    if (values.pipProgressMeetings !== undefined && typeof values.pipProgressMeetings !== 'string') {
      values.pipProgressMeetings = JSON.stringify(values.pipProgressMeetings);
    }
    return convertToFlowableVariables(values);
  };

  const onCreateNewDocument = (documentId: string, templateType: TemplateType): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      let documentCreationTaskId;
      if (state.taskData?.currentTask.taskDefinitionKey === 'approver-form-task') {
        if (!state.taskData.caseData.documentCreationTaskId) {
          throw new Error('No documentCreationTaskId for approver-form-task');
        } else {
          documentCreationTaskId = state.taskData.caseData.documentCreationTaskId;
        }
      } else {
        documentCreationTaskId = state.taskData?.currentTask.id;
      }
      const documentParams = {
        documentId: documentId,
        documentType: templateType,
        documentCreationTaskKey: state.taskData?.currentTask.taskDefinitionKey,
        documentCreationTaskId: documentCreationTaskId,
        ltTasksToCompleteOnIssuing: documentConfigs[templateType as TemplateType].ltTasksToCompleteOnIssuing,
      };

      const documentParamsVars: FlowableVariable[] = [
        { name: 'documentParams', value: JSON.stringify(documentParams) },
      ];
      const formValueVars = getFlowableVariables(state.lastFormValues);
      const vars = documentParamsVars.concat(formValueVars);
      if (state.taskData?.currentTask.processInstanceId && currentUser) {
        startNewDocumentFlow({
          processInstanceId: state.taskData.currentTask.processInstanceId,
          variables: vars,
          userId: currentUser.id,
        })
          .then(() => {
            setState(oldState => ({ ...oldState, hasJustCompletedATask: true }));
            loadCaseData(masterProcessInstanceId).then(() => resolve());
          })
          .catch(error => {
            setState(oldState => ({ ...oldState, loading: false }));
            handleError(error);
          });
      }
    });
  };

  const onOpenNewDocument = async (
    formValues: FormikValues,
    formFields: Record<string, any>,
    documentType: TemplateType,
  ): Promise<void> => {
    if (state.taskData?.caseData) {
      const caseData = state.taskData.caseData;
      const values = filterFormData(formValues, formFields);
      const flowableVariables = getFlowableVariables(values);
      Object.keys(values).forEach(key => (caseData[key] = values[key]));
      setState(oldState => ({
        ...oldState,
        caseData: caseData,
        lastFormValues: filterFormData(formValues, formFields),
      }));
      // try this
      setDocument(null, false, documentType, false);
      await updateProcessInstanceVariables(state.taskData.currentTask.processInstanceId, flowableVariables);
    }
  };

  const setDocument = (
    documentId: string | null,
    isDocumentReadOnly: boolean,
    templateType: TemplateType | null,
    showSignedCopy: boolean,
  ): void => {
    if (state.taskData?.caseData && state.metaData && masterProcessInstanceId) {
      const documentEditorProps: DocumentEditorProps = {
        documentId,
        templateType,
        isDocumentReadOnly,
        employee: state.metaData.employee,
        caseData: state.taskData.caseData,
        templateLogo: state.metaData.templateLogo,
        masterProcessInstanceId,
        clearDocument,
        completeTask,
        onCreateNewDocument,
        showSignedCopy,
        caseType: state.metaData?.caseType ?? CaseType.NONE,
      };
      setState(prevState => ({ ...prevState, documentEditorProps: documentEditorProps }));
    }
  };

  const closeCase = async () => {
    await updateVariables([
      { name: 'isCaseClosed', value: true },
      { name: 'caseStatus', value: 'Case Closed' },
    ]);
  };

  useEffect(() => {
    if (masterProcessInstanceId) {
      loadCaseData(masterProcessInstanceId).catch(error => handleError(error));
    } else {
      const error = new Error('Could not get processInstanceId from route.');
      handleError(error);
    }
  }, [masterProcessInstanceId, handleError]);

  useWhyDidYouUpdate('test', state);

  const renderTaskData = (
    taskData: TaskData,
    metaData: MetaData,
    currentTask: FlowableTask | FlowableHistoricTask,
    masterProcessInstanceId: string,
  ): JSX.Element => {
    return (
      <TaskContainer
        isTaskReadOnly={
          state.isTaskReadOnly || (taskData.caseData.isCaseClosed && !currentTask.formKey?.includes('ccma'))
        }
      >
        {renderComponentByFormKey(
          currentTask.formKey ? currentTask.formKey : '',
          {
            completeTask: completeTask,
            goBack: goBack,
            onNext: onNext,
            onSaveAndClose: onSaveAndClose,
            formKey: currentTask.formKey ? currentTask.formKey : '',
            formData: taskData.formData,
            setDocument: setDocument,
            clearDocument: clearDocument,
            onOpenNewDocument: onOpenNewDocument,
            closeCase: closeCase,
            loadSuspensionStatus: loadSuspensionStatus,
          },
          metaData.employee,
          taskData.caseData,
          currentTask.processInstanceId,
          metaData.organisation,
          metaData.caseOwnerDetails,
          masterProcessInstanceId,
          state.showFirstTaskWarning,
          state.disableBackButton,
          taskData,
          state.taskConfig,
          metaData.caseType,
          state.disableFooter,
        )}
      </TaskContainer>
    );
  };

  const renderDocumentEditor = (
    caseData: CaseData,
    documentEditorProps: DocumentEditorProps,
    masterProcessInstanceId: string,
  ): JSX.Element => {
    return (
      <DocumentEditor
        documentId={documentEditorProps.documentId}
        isDocumentReadOnly={documentEditorProps.isDocumentReadOnly}
        templateType={documentEditorProps.templateType}
        templateLogo={documentEditorProps.templateLogo}
        employee={documentEditorProps.employee}
        caseData={caseData}
        masterProcessInstanceId={masterProcessInstanceId}
        completeTask={completeTask}
        onCreateNewDocument={onCreateNewDocument}
        clearDocument={clearDocument}
        showSignedCopy={documentEditorProps.isDocumentReadOnly}
        caseType={documentEditorProps.caseType}
      />
    );
  };

  const renderWorkflowContent = (
    taskData: TaskData | null,
    metaData: MetaData | null,
    masterProcessInstanceId: string,
    isLoading: boolean,
  ): JSX.Element => {
    if (isLoading) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <Loader />
        </div>
      );
    } else if (masterProcessInstanceId && metaData) {
      return (
        <>
          <CaseStatusBar status={metaData.caseStatus} />
          <div className="workflow-content">
            {metaData.progressBarData && (
              <div>
                <div className="ml-3 mt-3">
                  <CaseProgressBar
                    stepIndex={metaData.progressBarData.stepIndex}
                    steps={metaData.progressBarData.steps}
                  />
                </div>
              </div>
            )}
            <div className="task-container">
              {taskData && (
                <>
                  <div
                    className="position-fixed right-4"
                    style={{ top: '35%', zIndex: 1000, display: 'flex', flexDirection: 'row' }}
                  >
                    <AdhocContainer processInstanceId={masterProcessInstanceId} caseData={taskData.caseData} />
                    <UserInfoPopup employeeId={metaData.employee?.id} isSuspended={metaData?.isSuspended} />
                  </div>
                </>
              )}
              <div>
                {taskData ? (
                  renderTaskData(taskData, metaData, taskData.currentTask, masterProcessInstanceId)
                ) : (
                  <div className="text-primary text-center mt-5">
                    {state.hasJustCompletedATask
                      ? 'Thank you!'
                      : 'There are no tasks currently assigned to you on this case.'}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return <div className="text-primary text-center mt-5">Tasks complete</div>;
    }
  };

  const render = (): JSX.Element => {
    if ((state.metaData || state.loading) && masterProcessInstanceId) {
      if (state.documentEditorProps && state.taskData) {
        return renderDocumentEditor(state.taskData.caseData, state.documentEditorProps, masterProcessInstanceId);
      } else {
        return renderWorkflowContent(state.taskData, state.metaData, masterProcessInstanceId, state.loading);
      }
    } else {
      return (
        <div className="text-primary text-center mt-5">There are no tasks currently assigned to you on this case</div>
      );
    }
  };

  return (
    <>
      <TopBarComponent
        title={'cases'}
        subTitle={
          'new case' +
          (state.metaData ? ': ' + state.metaData.employee?.firstName + ' ' + state.metaData.employee?.lastName : '')
        }
        hideSearch={true}
      />
      {render()}
    </>
  );
};
