import React, { ReactElement } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { styles } from '../../../PDF/PDFStyles.style';
import Table from '../../../PDF/PDFTable/Table/Table.component';
import TableHeader from '../../../PDF/PDFTable/TableHeader.component';
import TableRow from '../../../PDF/PDFTable/TableRow.component';
import TableCell from '../../../PDF/PDFTable/TableCell.component';
import List from '../../../PDF/List.component';
import ListItem from '../../../PDF/ListItem.component';
import Paragraph from '../../../PDF/Paragraph.component';
import moment from 'moment';
import LetterHead from '../../../PDF/Letterhead.component';
import { ConfirmationOfReceiptPage } from '../../common/ConfirmationOfReceiptPage';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';

export const NoticeOfHearingPDF: React.FC<DocumentProps> = (props: DocumentProps): ReactElement => {
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        {!!props.logo && <LetterHead imageUrl={props.logo.imageUrl} logoPosition={props.logo.position} />}
        <Text style={styles.title}>Confidential</Text>
        <Table>
          <TableHeader title="attention" />
          <TableRow>
            <TableCell>Name of Employee: </TableCell>
            <TableCell>{props.content.employeeFullName.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Employee Number: </TableCell>
            <TableCell>{props.content.employeeNumber.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Department/Business Unit</TableCell>
            <TableCell>{props.content.employeeDepartment.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{props.content.employeeAddress.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>{props.content.date.value}</TableCell>
          </TableRow>
        </Table>
        <Paragraph>Dear {props.content.employeeFirstName.value},</Paragraph>
        <Text style={{ ...styles.subject }}>NOTICE OF DISCIPLINARY HEARING </Text>
        <Paragraph>
          Following allegations of misconduct against you, a disciplinary hearing will take place as follows:
        </Paragraph>
        <Text style={{ ...styles.text, ...styles.bold }}>
          Date: {''}
          {moment.isMoment(props.content.initial_hearing_hearingDate.value)
            ? ' ' + props.content.initial_hearing_hearingDate.value.format('DD-MM-YYYY')
            : props.content.initial_hearing_hearingDate.value}{' '}
        </Text>
        <Text style={{ ...styles.text, ...styles.bold }}>
          Time: {''}
          {moment.isMoment(props.content.initial_hearing_hearingTime.value)
            ? ' ' + props.content.initial_hearing_hearingTime.value.format('DD-MM-YYYY')
            : props.content.initial_hearing_hearingTime.value}{' '}
          {/*{typeof props.content.time !== 'string'*/}
          {/*  ? props.content.time.format('HH:mm') + ' hrs'*/}
          {/*  : props.content.time + ' hrs'}*/}
        </Text>
        <Text style={{ ...styles.text, ...styles.bold }}>
          Place: {props.content.initial_hearing_hearingLocation.value}
        </Text>
        <Paragraph>
          The purpose of the disciplinary hearing is to allow you to respond to the allegations made against you. At the
          hearing you will be given a full opportunity to state your case in response. Please see the attached charge
          sheet containing details of the misconduct allegedly committed by you - Annexure A.
        </Paragraph>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={{ ...styles.text, ...styles.fonts }}>The disciplinary hearing will be chaired by:</Text>
          <Text style={{ ...styles.text, ...styles.bold, marginTop: 2, paddingLeft: 3 }}>
            {props.content.initial_hearing_chairpersonFirstName.value +
              ' ' +
              props.content.initial_hearing_chairpersonLastName.value}
          </Text>
        </View>
        <Text style={{ ...styles.text, ...styles.bold }}>EMPLOYEE RIGHTS </Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>You have the following rights:</Text>
        <List>
          <ListItem>You are entitled to lead your case and submit evidence, including documentation.</ListItem>
          <ListItem>
            You may call witnesses, but it is your own responsibility to secure their presence at the hearing, subject
            to any suspension conditions that may be applicable. Their participation is voluntary. Please inform the
            employer at least 24 hours in advance of the hearing if you have arranged with any of its employees to
            appear as your witness in order for it to confirm the person’s absence from the workplace with his/her line
            manager.
          </ListItem>
          <ListItem>
            You or your representative, if any, are entitled to cross-examine witnesses called by the employer.
          </ListItem>
          <ListItem>
            You are entitled to confer with your representative, if any, at reasonable times before, during and after
            the hearing.
          </ListItem>
          <ListItem>
            You are entitled to furnish evidence and to argue in mitigation of disciplinary action, as appropriate.
          </ListItem>
          <ListItem>
            You have the right to attend the hearing. However, should you fail or refuse to attend the hearing and fail
            to timeously provide the employer with an acceptable reason for your failure / refusal, the hearing may be
            held in your absence without any further notice to yourself and action taken accordingly.
          </ListItem>
        </List>
        <Paragraph>
          <Text>{props.content.other.value}</Text>
        </Paragraph>
        <Text style={{ ...styles.text, ...styles.bold }}>CONCLUSION</Text>
        <Paragraph>
          Should you fail to understand the contents of this notice, please approach {props.content.authorName.value} to
          explain. This letter is provided in duplicate, alternatively, was sent to you via e-mail. Please sign and
          return a copy to writer hereof in acknowledgement of receipt hereof, alternatively, acknowledge by way of
          return e-mail.
        </Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>Regards</Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorName.value}</Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorJobTitle.value}</Text>
      </Page>
      {ConfirmationOfReceiptPage(props)}
      <Page style={styles.body} size="A4" wrap>
        <View>
          <Text style={{ ...styles.text, ...styles.bold }}>Employee responses:</Text>
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ ...styles.text, ...styles.fonts }}>
              A translator is required / not. If so, state language
            </Text>
            <Text
              style={{
                ...styles.line,
                ...styles.text,
                marginTop: -20,
                marginLeft: 4,
              }}
            >
              {'                               '}
            </Text>
          </View>
        </View>
      </Page>

      <Page style={styles.body} size="A4" wrap>
        <Text style={{ ...styles.text, ...styles.subject }}>ANNEXURE A – CHARGE SHEET </Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        {Array.isArray(props.content.charges.value) &&
          props.content.charges.value.map((item: string, index: number) => (
            <View key={index}>
              <Text style={{ ...styles.text, ...styles.bold }}>
                {'Charge ' + (index + 1).toString() + ': ' + item.toString()}
              </Text>
              {Array.isArray(props.content.allegations.value) && (
                <Text style={{ ...styles.text, ...styles.fonts }}>
                  More particularly, it is alleged that {props.content.allegations.value[index]?.toString()}{' '}
                </Text>
              )}
              {Array.isArray(props.content.charges.value) && index !== props.content.charges.value.length - 1 && (
                <Text style={{ ...styles.text, ...styles.bold }}>and/or</Text>
              )}
            </View>
          ))}
      </Page>
    </Document>
  );
};
