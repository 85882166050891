// const validateDMs = () => {
// //  1. upload sheet must account for all DM ids.
// //  2. upload sheet may not contain any circularities.
// }
//
// const uploadChanges = () => {
// //  1. remove leavers (update deleted)
// //  2. insert joiners
// //  3. update the updated
// //  4. resolve DMs from employee numbers
// }
//
// const updateDMs = () => {
// //  1. fetch all employees with DMIds, but only id uuid fields.
// //  2. for each employee, find another employee with id that matches the first employee's DM Id and assign that employee's
// //  id as the first employee's DM Id.
// }

import { CreateEmployeeInput, UpdateEmployeeInput } from '../../API';
import { list, mutate } from '../../utils/graphql-utils';
import { createEmployee, updateEmployee } from '../../graphql/mutations';
import { AttemptedEmployee } from './upload-validation-utils';
import { listEmployees } from '../../graphql/queries';
import { EmployeeImportResults } from './EmployeeUploader';

export const deleteEmployeeMutation = /* GraphQL */ `
  mutation UpdateEmployee($input: UpdateEmployeeInput!) {
    updateEmployee(input: $input) {
      id
      deleted
      firstName
      lastName
      employeeNumber
      idNumber
    }
  }
`;

const addNewEmployee = async (employee: CreateEmployeeInput): Promise<any> => {
  console.log('adding... ', employee);
  return new Promise((resolve, reject) => {
    mutate(createEmployee, employee)
      .then(async employeeResponse => {
        resolve(employeeResponse);
      })
      .catch(error => reject(error));
  });
};

const updateExistingEmployee = async (employee: UpdateEmployeeInput): Promise<any> => {
  return new Promise((resolve, reject) => {
    mutate(updateEmployee, employee)
      .then(async employeeResponse => {
        resolve(employeeResponse);
      })
      .catch(error => reject(error));
  });
};

const deleteEmployee = async (employeeId: string): Promise<any> => {
  const vars = { id: employeeId, deleted: true };
  return new Promise((resolve, reject) => {
    mutate(deleteEmployeeMutation, vars)
      .then(async employeeResponse => {
        resolve(employeeResponse);
      })
      .catch(error => reject(error));
  });
};

const getUploadPromisesCategories = (
  organisationId: string,
  joiners: AttemptedEmployee[],
  leavers: AttemptedEmployee[],
  updated: AttemptedEmployee[],
): {
  employeesToBeCreated: Promise<any>[];
  employeesToBeUpdated: Promise<any>[];
  employeesToBeDeleted: Promise<any>[];
  rejoinerIds: string[];
  deletedIds: string[];
} => {
  const employeesToBeCreated = joiners
    .filter(e => !e.isRejoining)
    .map(e => addNewEmployee(e.employeeData as CreateEmployeeInput));
  const rejoiners = joiners.filter(e => e.isRejoining);
  rejoiners.forEach(e => (e.employeeData.deleted = false));
  const rejoinerIds = rejoiners.filter(e => !!e.employeeData.id).map(e => e.employeeData.id as string);
  const employeesToBeUpdated = [...updated, ...rejoiners].map(e =>
    updateExistingEmployee(e.employeeData as UpdateEmployeeInput),
  );
  const employeesToBeDeleted = leavers
    .filter(e => !!e.employeeData.id)
    .map(e => deleteEmployee(e.employeeData.id as string));
  const deletedIds = leavers.filter(e => !!e.employeeData.id).map(e => e.employeeData.id as string);
  return { employeesToBeCreated, employeesToBeUpdated, employeesToBeDeleted, rejoinerIds, deletedIds };
};

const getManagerUpdatePromises = (
  newOrUpdated: (UpdateEmployeeInput | CreateEmployeeInput)[],
  employeeManagerInfos: {
    employeeNumber: string | null;
    dmNumber: string | null;
    amNumber: string | null;
  }[],
  allEmployees: UpdateEmployeeInput[],
): Promise<any>[] => {
  console.log('fetching managers...');
  const emps: UpdateEmployeeInput[] = [];
  for (const employeeMangerRelation of employeeManagerInfos) {
    const dbEmployee = allEmployees.find(e => e.employeeNumber === employeeMangerRelation.employeeNumber);
    console.log('We are here', dbEmployee);
    if (dbEmployee) {
      if (employeeMangerRelation.dmNumber === 'topOfReportingLine') {
        console.log('We are correct');
        dbEmployee.directManagerID = 'topOfReportingLine';
      } else {
        const dm = allEmployees.find(e => e.employeeNumber === employeeMangerRelation.dmNumber);
        if (dm) {
          dbEmployee.directManagerID = dm.id;
        } else {
          dbEmployee.directManagerID = 'none';
        }
      }

      if (employeeMangerRelation.amNumber) {
        const am = allEmployees.find(e => e.employeeNumber === employeeMangerRelation.amNumber);
        if (am) {
          dbEmployee.allocatedManagerID = am.id;
        } else {
          dbEmployee.allocatedManagerID = 'none';
        }
      }
      emps.push(dbEmployee);
    } else {
      console.log('*** OUtside');
    }
  }

  return emps.map((item: UpdateEmployeeInput) => {
    return updateExistingEmployee({
      id: item.id,
      directManagerID: item.directManagerID,
      allocatedManagerID: item.allocatedManagerID,
    });
  });
};

export const importEmployees = async (
  organisationId: string,
  joiners: AttemptedEmployee[],
  leavers: AttemptedEmployee[],
  updated: AttemptedEmployee[],
): Promise<EmployeeImportResults> => {
  console.log('joiners: ', joiners);
  console.log('leavers: ', leavers);
  console.log('updated: ', updated);

  const data = getUploadPromisesCategories(organisationId, joiners, leavers, updated);
  const responses = await Promise.all([
    ...data.employeesToBeCreated,
    ...data.employeesToBeUpdated,
    ...data.employeesToBeDeleted,
  ]);

  const successfulJoiners: (CreateEmployeeInput | UpdateEmployeeInput)[] = [];
  const successfulLeavers: UpdateEmployeeInput[] = [];
  const successfulUpdated: UpdateEmployeeInput[] = [];
  const failed: unknown[] = [];

  for (const res of responses) {
    if (res.data.createEmployee) {
      successfulJoiners.push(res.data.createEmployee);
    } else if (res.data.updateEmployee) {
      if (data.rejoinerIds.includes(res.data.updateEmployee.id)) {
        successfulJoiners.push(res.data.updateEmployee);
      } else if (data.deletedIds.includes(res.data.updateEmployee.id)) {
        successfulLeavers.push(res.data.updateEmployee);
      } else {
        successfulUpdated.push(res.data.updateEmployee);
      }
    } else if (res.errors?.length) {
      failed.push(res);
    }
  }

  const employeeManagerInfo = [...joiners, ...updated].map(e => {
    return {
      employeeNumber: e.employeeData.employeeNumber ?? null,
      dmNumber: e.directManagerEmployeeNumber ?? null,
      amNumber: e.allocatedManagerEmployeeNumber ?? null,
    };
  });

  // for (const res of responses) {
  //   if (res.data.createEmployee) {
  //     successfulJoinerEmployeeNumbers.push(res.data.createEmployee.employeeNumber);
  //   } else if (res.data.updateEmployee) {
  //     if (data.rejoinerIds.includes(res.data.updateEmployee.id)) {
  //       successfulJoinerEmployeeNumbers.push(res.data.updateEmployee.employeeNumber);
  //     } else if (data.deletedIds.includes(res.data.updateEmployee.id)) {
  //       successfulLeaverIds.push(res.data.updateEmployee.id);
  //     } else {
  //       successfulUpdatedIds.push(res.data.updateEmployee.id);
  //     }
  //   } else if (res.errors?.length) {
  //     failed.push(res);
  //   }
  // }
  //
  // // use employeeNumber because employees don't have ids until they are created on the database.
  // const successfulJoiners = joiners.filter(
  //   e => e.employeeData.employeeNumber && successfulJoinerEmployeeNumbers.includes(e.employeeData.employeeNumber),
  // );
  // joiners.forEach(joiner => {
  //   const createdEmployee = successfulJoinerEmployeeNumbers.find(i => i === joiner.employeeData.employeeNumber);
  //   if (createdEmployee) {
  //     successfulJoiners.push();
  //   }
  // });
  // const successfulLeavers = leavers.filter(e => e.employeeData.id && successfulLeaverIds.includes(e.employeeData.id));
  // const successfulUpdated = updated.filter(e => e.employeeData.id && successfulUpdatedIds.includes(e.employeeData.id));

  const variables = { filter: { organisationId: { eq: organisationId } }, limit: 250 };

  const employeesRes = await list(listEmployees, variables);
  if (employeesRes.data && (employeesRes.data as any).listEmployees) {
    const allEmployees: UpdateEmployeeInput[] = (employeesRes.data as any).listEmployees.items;
    const managerPromises = getManagerUpdatePromises(
      [...successfulJoiners, ...successfulUpdated],
      employeeManagerInfo,
      allEmployees,
    );
    console.log('updating managers...');
    await Promise.all(managerPromises);
    const results = { successfulJoiners, successfulUpdated, successfulLeavers, failed };
    console.log('import results: ', results);
    return results;
  } else {
    throw new Error('could not retrieve employees');
  }
};
