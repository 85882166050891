import {
  ErrorMessage,
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FieldAttributes,
  FormikValues,
  useFormikContext,
} from 'formik';
import React, { useContext, useEffect } from 'react';
import Select, { ValueType } from 'react-select';
import { Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { CaseType } from '../../../../../API';
import {
  selectStyles,
  SingleValue,
  tableFieldSelectStyles,
  ValueContainer,
} from '../../../../../components/reactSelect/ReactSelectComponents.component';
import FormField from '../../../../../forms/fields/FormField.component';
import TableFormField from '../../../../../forms/fields/TableFormField';
import { toTitleCase } from '../../../../../utils/string-utils';
import { CaseIncident, CasePerformanceShortfall, FormProps } from '../../../../WorkflowContainer/workflow-utils';
import {
  chairpersonsFindingsOptions,
  misconductSanctionOptions,
  pp_sanctionOptionsFirstTime,
  pp_sanctionOptionsSecondTime,
} from './sanction-options';
import { EmailPreviewModalv3 } from '../../../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { UserContext } from '../../../../../Router';
import { UserContextProps } from '../../../../../App';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../../../../components/taskContainer/TaskContainer';
import { EmailParamsV2, EmailRecipient, EmailType } from '../../../../../utils/email-utils';
import { AxiosResponse } from 'axios';
import { appointHcApprover } from '../../../../../utils/user-utils';
import AsyncSelect from 'react-select/async';
import { loadOptions } from '../../../../../utils/employee-user-selector';

const incidentColumns = ['Summary of Allegations', 'date', 'time', 'transgression', 'ruling on guilt', 'sanction'];
const shortfallColumns = ['performance shortfalls', 'date', 'ruling on guilt', 'sanction'];

const hcApproverColumns = [
  'Transgression',
  'Ruling on Guilt',
  'HC Approver decision ',
  'HC motivation for deviation ',
  'Sanction',
  'HC Approver decision',
  'HC motivation for deviation',
];

const InitialHearingConfirmOverallSanctionForm: React.FC<FormProps> = props => {
  const { setFieldValue } = useFormikContext();
  const { values } = useFormikContext<FormikValues>();
  const { caseData } = props.data;
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;

  const handleChange = (item: {
    value: string;
    label: string;
    employeeId: string;
    userId: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
  }): void => {
    console.log('item', item);
    setFieldValue('hc_approverFirstName', item.firstName);
    setFieldValue('hc_approverLastName', item.lastName);
    setFieldValue('hc_approverEmailAddress', item.emailAddress);

    setFieldValue('hc_approverUserId', item.userId);
    setFieldValue('hc_approverEmployeeId', item.employeeId);
    setFieldValue('hc_approverId', item.value);

    setFieldValue('addresseeFirstName', item.firstName);
    setFieldValue('employeeFullName', item.lastName);
    setFieldValue('initial_hearing_hearingDate', values.incidents[0].date);
    setFieldValue('contactName', item.firstName);

    setFieldValue('initial_hearing_chairpersonFirstName', item.firstName);
    setFieldValue('initial_hearing_chairpersonLastName', item.lastName);
    setFieldValue('initial_hearing_chairpersonEmailAddress', item.emailAddress);
    setFieldValue('initial_hearing_chairpersonUserId', item.userId);
    setFieldValue('initial_hearing_chairpersonEmployeeId', item.employeeId);
    setFieldValue('initial_hearing_chairpersonId', item.value);

    setFieldValue('HCApproverID', item.userId);
  };

  const formatOption = (str: string): { label: string; value: string } => ({
    label: toTitleCase(str, '_'),
    value: str,
  });

  const getMisconductSanctionOptions = (index: number): { label: string; value: string }[] => {
    if (values.incidents[index].initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY') {
      return misconductSanctionOptions.filter(s => s !== 'NOT_APPLICABLE').map(formatOption);
    } else {
      return misconductSanctionOptions.map(formatOption);
    }
  };

  const getPPSanctionSanctionOptions = (index: number): { label: string; value: string }[] => {
    if (values.performanceShortfalls[index].initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY') {
      return getOptionsForPP()
        .filter(s => s !== 'NOT_APPLICABLE')
        .map(formatOption);
    } else {
      return getOptionsForPP().map(formatOption);
    }
  };

  const canSelectOverallSanction = (): boolean => {
    if (props.data.caseType === CaseType.POOR_PERFORMANCE) {
      return values.performanceShortfalls.some(
        (i: CaseIncident) => i.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY',
      );
    } else {
      return values.incidents.some((i: CaseIncident) => i.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY');
    }
  };

  const getOptionsForOverallSanction = (): { label: string; value: string }[] => {
    if (props.data.caseType === CaseType.MISCONDUCT) {
      if (canSelectOverallSanction()) {
        return misconductSanctionOptions.filter(s => s !== 'NOT_APPLICABLE').map(formatOption);
      } else return [formatOption('NOT_APPLICABLE')];
    } else {
      if (canSelectOverallSanction()) {
        console.log('cheeeeck: ', getOptionsForPP());
        return getOptionsForPP()
          .filter(s => s !== 'NOT_APPLICABLE')
          .map(formatOption);
      } else return [formatOption('NOT_APPLICABLE')];
    }
  };

  const getOptionsForPP = (): string[] => {
    if (caseData.hasCompletedExtendedPIP || caseData.hasCompletedFinalWrittenWarningPIP) {
      return pp_sanctionOptionsSecondTime;
    } else {
      return pp_sanctionOptionsFirstTime;
    }
  };

  const getRecipients = (): EmailRecipient[] => {
    return [
      {
        emailAddress: values.hc_approverEmailAddress,
        name: toTitleCase(values.hc_approverFirstName, ' '),
      },
    ];
  };

  const sendEmail = (): Promise<AxiosResponse> => {
    delete values.incidents;

    if (!caseData?.organisationId) {
      throw new Error('No current user with organisation id');
    }
    if (!currentUser?.id) {
      throw new Error('No current user id');
    }

    delete values.incidents;
    const params: EmailParamsV2 = {
      attachmentBucketKeys: [],
      currentUserId: currentUser?.id,
      emailType: EmailType.HC_APPROVER,
      formValues: values,
      masterProcessInstanceId: props.data.masterProcessInstanceId,
      processInstanceId: props.data.processInstanceId,
      recipients: getRecipients(),
    };

    return appointHcApprover(
      params,
      props.getFormValuesForSubmission(values),
      values.initial_hearing_chairpersonEmailAddress,
      values.initial_hearing_chairpersonFirstName,
      values.initial_hearing_chairpersonLastName,
      values.initial_hearing_chairpersonEmployeeId,
      values.initial_hearing_chairpersonType === 'internal' ? caseData?.organisationId : undefined,
    );
  };

  useEffect(() => {
    if (!values.initial_hearing_chairpersonType) setFieldValue('initial_hearing_chairpersonType', 'internal');
  }, [values.initial_hearing_chairpersonType]);

  useEffect(() => {
    if (props.data.caseType === CaseType.MISCONDUCT) {
      values.incidents.forEach((incident: CaseIncident, index: number) => {
        if (incident.initial_hearing_chairpersonsFindingOnGuilt === 'NOT_GUILTY') {
          setFieldValue(`incidents.${index}.initial_hearing_chairpersonsSanction`, 'NOT_APPLICABLE');
        } else if (incident.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY') {
          if (incident.initial_hearing_chairpersonsSanction === 'NOT_APPLICABLE') {
            setFieldValue(`incidents.${index}.initial_hearing_chairpersonsSanction`, '');
          }
        }
      });
      if (
        !values.incidents.some(
          (incident: CaseIncident) => incident.initial_hearing_chairpersonsFindingOnGuilt !== 'NOT_GUILTY',
        )
      ) {
        setFieldValue('overallSanction', 'NOT_APPLICABLE');
      } else if (values.overallSanction === 'NOT_APPLICABLE') {
        setFieldValue('overallSanction', '');
      }
    } else if (props.data.caseType === CaseType.POOR_PERFORMANCE) {
      if (
        !values.performanceShortfalls.some(
          (shortfall: CasePerformanceShortfall) => shortfall.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY',
        )
      ) {
        setFieldValue('overallSanction', 'NOT_APPLICABLE');
      }
    }
  }, [JSON.stringify(values.incidents), values.performanceShortfalls]);

  return (
    <Form>
      {props.data.caseType === CaseType.MISCONDUCT && (
        <>
          <Row>
            <Col>
              <div>
                <p className="text-primary text-capitalize font-weight-normal">Charges</p>
                <hr style={{ border: '0.06em solid #adb5bd' }} />
                <Row>
                  <Col>
                    <Table className="table-responsive-lg" bordered>
                      <thead>
                        <tr>
                          {incidentColumns.map(column => (
                            <th key={column}>
                              <span className="text-blue font-weight-500 text-capitalize">{column}</span>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name={'incidents'}>
                          {(arrayHelpers: FieldArrayRenderProps) => (
                            <>
                              {!values.incidents && arrayHelpers.push({})}
                              {values.incidents &&
                                values.incidents.length &&
                                values.incidents.map((item: any, index: number) => (
                                  <tr key={index}>
                                    <td style={{ minWidth: '100px' }}>
                                      <TableFormField
                                        type={'text'}
                                        placeholder={'Summary of Facts'}
                                        name={`incidents.${index}.summaryOfFacts`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage
                                          className="text-danger"
                                          name={`incidents.${index}.summaryOfFacts`}
                                        />
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ maxWidth: '130px', minWidth: '130px' }}>
                                      <TableFormField
                                        type={'date'}
                                        placeholder={'Date'}
                                        name={`incidents.${index}.date`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={`incidents.${index}.date`} />
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ maxWidth: '130px', minWidth: '130px' }}>
                                      <TableFormField
                                        type={'time'}
                                        placeholder={'Time'}
                                        name={`incidents.${index}.time`}
                                        className="square-radius border-0"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={`incidents.${index}.time`} />
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '150px' }}>
                                      <span> {item.transgression}</span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '150px' }}>
                                      <TableFormField
                                        type={'select'}
                                        placeholder={'Select Ruling'}
                                        name={`incidents.${index}.initial_hearing_chairpersonsFindingOnGuilt`}
                                        selectOptions={chairpersonsFindingsOptions.map(formatOption)}
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage
                                          className="text-danger"
                                          name={`incidents.${index}.initial_hearing_chairpersonsFindingOnGuilt`}
                                        />
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '150px' }}>
                                      {!!item.initial_hearing_chairpersonsFindingOnGuilt?.length && (
                                        <TableFormField
                                          disabled={item.initial_hearing_chairpersonsFindingOnGuilt === 'NOT_GUILTY'}
                                          type={'select'}
                                          placeholder={'Select Sanction'}
                                          name={`incidents.${index}.initial_hearing_chairpersonsSanction`}
                                          selectOptions={getMisconductSanctionOptions(index)}
                                        />
                                      )}
                                      <span className="text-danger">
                                        <ErrorMessage
                                          className="text-danger"
                                          name={`incidents.${index}.initial_hearing_chairpersonsSanction`}
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </>
                          )}
                        </FieldArray>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="Overall Sanction" className="text-default text-capitalize">
                  Confirm Overall sanction
                </Label>
                <FormField
                  disabled={!canSelectOverallSanction()}
                  type={'selectWithLabels'}
                  placeholder={'Select Overall Sanction'}
                  name={'overallSanction'}
                  selectOptionsWithLabels={getOptionsForOverallSanction()}
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'overallSanction'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}

      {props.data.caseType === CaseType.POOR_PERFORMANCE && (
        <>
          <Row>
            <Col>
              <div>
                <Row>
                  <Col>
                    <Table className="table-responsive-lg" bordered>
                      <thead>
                        <tr>
                          {shortfallColumns.map(column => (
                            <th key={column}>
                              <span className="text-blue font-weight-500 text-capitalize">{column}</span>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name={'performanceShortfalls'}>
                          {(arrayHelpers: FieldArrayRenderProps) => (
                            <>
                              {!values.performanceShortfalls && arrayHelpers.push({})}
                              {values.performanceShortfalls &&
                                values.performanceShortfalls.length &&
                                values.performanceShortfalls.map((item: CasePerformanceShortfall, index: number) => (
                                  <tr key={index}>
                                    <td className="position-relative" style={{ minWidth: '150px' }}>
                                      <span> {item.shortfallDescription}</span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '100px' }}>
                                      <span> {item.date}</span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '150px' }}>
                                      <Field
                                        name={`performanceShortfalls.${index}.initial_hearing_chairpersonsFindingOnGuilt`}
                                      >
                                        {({ field }: FieldAttributes<FormikValues>) => (
                                          <Select
                                            {...field}
                                            placeholder="Select Ruling"
                                            cacheOptions
                                            options={[
                                              { label: 'Guilty', value: 'GUILTY' },
                                              { label: 'Not guilty', value: 'NOT_GUILTY' },
                                            ]}
                                            closeMenuOnSelect={true}
                                            styles={tableFieldSelectStyles}
                                            onChange={(value: ValueType<any>): void => {
                                              setFieldValue(
                                                `performanceShortfalls.${index}.initial_hearing_chairpersonsFindingOnGuilt`,
                                                value.value,
                                              );
                                            }}
                                            value={{
                                              label: item.initial_hearing_chairpersonsFindingOnGuilt,
                                              value: item.initial_hearing_chairpersonsFindingOnGuilt,
                                            }}
                                            components={{ SingleValue }}
                                          />
                                        )}
                                      </Field>
                                      <span className="text-danger">
                                        <ErrorMessage
                                          className="text-danger"
                                          name={`performanceShortfalls.${index}.initial_hearing_chairpersonsFindingOnGuilt`}
                                        />
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '150px' }}>
                                      {item.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY' && (
                                        <Field
                                          name={`performanceShortfalls.${index}.initial_hearing_chairpersonsSanction`}
                                        >
                                          {({ field }: FieldAttributes<FormikValues>) => (
                                            <Select
                                              {...field}
                                              placeholder="Select Sanction"
                                              cacheOptions
                                              options={getPPSanctionSanctionOptions(index)}
                                              closeMenuOnSelect={true}
                                              styles={tableFieldSelectStyles}
                                              onChange={(value: ValueType<any>): void => {
                                                setFieldValue(
                                                  `performanceShortfalls.${index}.initial_hearing_chairpersonsSanction`,
                                                  value.value,
                                                );
                                              }}
                                              value={{
                                                label: item.initial_hearing_chairpersonsSanction,
                                                value: item.initial_hearing_chairpersonsSanction,
                                              }}
                                              components={{ SingleValue }}
                                            />
                                          )}
                                        </Field>
                                      )}
                                      <span className="text-danger">
                                        <ErrorMessage
                                          className="text-danger"
                                          name={`performanceShortfalls.${index}.initial_hearing_chairpersonsSanction`}
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </>
                          )}
                        </FieldArray>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="Overall Sanction" className="text-default text-capitalize">
                  Confirm Overall sanction
                </Label>
                <FormField
                  disabled={!canSelectOverallSanction()}
                  type={'selectWithLabels'}
                  placeholder={'Select Overall Sanction'}
                  name={'overallSanction'}
                  selectOptionsWithLabels={getOptionsForOverallSanction()}
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'overallSanction'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      {/* @Paul Logic for showing or not showing HC Approver to companies should be below this */}
      <Row>
        <Col>
          <h5 className="text-h5 text-blue font-weight-500 mt-3">Send Chairperson recommendation for review</h5>
          <p style={{ color: 'red' }}>
            The Chairperson recommendation can only be sent to an HC approver who was NOT involved in the hearing
            preparation. <u>Please check your policy provisions to identify approvers</u>.
          </p>
        </Col>
      </Row>

      {values.initial_hearing_chairpersonType === 'internal' && (
        <Row className="mt-4 mb-4">
          <Col md={3}>
            <FormGroup>
              <Label for="employee" className="text-default text-capitalize">
                Select HC approver
              </Label>
              <Field name={'initial_hearing_chairpersonId'}>
                {({ field }: FieldAttributes<FormikValues>) => (
                  <AsyncSelect
                    placeholder="Select Employee"
                    cacheOptions
                    defaultOptions
                    value={{
                      label:
                        values.initial_hearing_chairpersonFirstName && values.initial_hearing_chairpersonLastName
                          ? values.initial_hearing_chairpersonFirstName +
                            ' ' +
                            values.initial_hearing_chairpersonLastName
                          : '',
                      value: values.initial_hearing_chairpersonId,
                    }}
                    loadOptions={(inputValue: string | null) => {
                      return loadOptions(
                        inputValue,
                        props.data.caseData.organisationId,
                        props.data.caseData.employeeId,
                      );
                    }}
                    closeMenuOnSelect={false}
                    styles={selectStyles}
                    onChange={(value: ValueType<any>): void => handleChange(value)}
                    components={{ ValueContainer }}
                    isDisabled={readOnly}
                  />
                )}
              </Field>
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'initial_hearing_chairpersonId'} />
              </span>
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <EmailPreviewModalv3
            buttonText={'Email HC approver'}
            getRecipients={getRecipients}
            disabled={
              !values.initial_hearing_chairpersonFirstName ||
              !values.initial_hearing_chairpersonLastName ||
              !values.initial_hearing_chairpersonEmailAddress ||
              readOnly
            }
            formValues={values}
            emailType={EmailType.HC_APPROVER}
            masterProcessInstanceId={props.data.masterProcessInstanceId}
            processInstanceId={props.data.processInstanceId}
            currentUserId={currentUser?.id}
            sendFunction={sendEmail}
            getFlowableVariables={() => []}
            getAttachmentBucketKeys={() => []}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div>
            <Row>
              <Col>
                <Table className="table-responsive-lg" bordered>
                  <thead>
                    <tr>
                      {hcApproverColumns.map(column => (
                        <th key={column}>
                          <span className="text-blue font-weight-500 text-capitalize">{column}</span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray name={'performanceShortfalls'}>
                      {(arrayHelpers: FieldArrayRenderProps) => (
                        <>
                          {!values.incidents && arrayHelpers.push({})}
                          {values.incidents &&
                            values.incidents.length &&
                            values.incidents.map((item: CasePerformanceShortfall, index: number) => (
                              <tr key={index}>
                                <td className="position-relative" style={{ minWidth: '150px' }}>
                                  <span>
                                    <TableFormField
                                      type={'textarea'}
                                      placeholder={'Transgression'}
                                      name={`incidents.${index}.transgression`}
                                      className="square-radius border-0 h-25 pt-4"
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage
                                        className="text-danger"
                                        name={`values.incidents.${index}.transgression`}
                                      />
                                    </span>
                                  </span>
                                </td>
                                <td className="position-relative" style={{ minWidth: '100px' }}>
                                  <span>
                                    <TableFormField
                                      type={'textarea'}
                                      placeholder={'Ruling on guilt'}
                                      name={`incidents.${index}.initial_hearing_chairpersonsFindingOnGuilt`}
                                      className="square-radius border-0 h-25 pt-4"
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage
                                        className="text-danger"
                                        name={`values.incidents.${index}.initial_hearing_chairpersonsFindingOnGuilt`}
                                      />
                                    </span>
                                  </span>
                                </td>
                                <td className="position-relative" style={{ minWidth: '150px' }}>
                                  <Field name={`incidents.${index}.initial_hearing_hcApproversFindingOnGuilt`}>
                                    {({ field }: FieldAttributes<FormikValues>) => (
                                      <Select
                                        {...field}
                                        placeholder="Select Ruling"
                                        cacheOptions
                                        options={[
                                          { label: 'Guilty', value: 'GUILTY' },
                                          { label: 'Not guilty', value: 'NOT_GUILTY' },
                                        ]}
                                        closeMenuOnSelect={true}
                                        styles={tableFieldSelectStyles}
                                        onChange={(value: ValueType<any>): void => {
                                          setFieldValue(
                                            `incidents.${index}.initial_hearing_hcApproversFindingOnGuilt`,
                                            value.value,
                                          );
                                        }}
                                        value={{
                                          label: item.initial_hearing_hcApproversFindingOnGuilt,
                                          value: item.initial_hearing_hcApproversFindingOnGuilt,
                                        }}
                                        components={{ SingleValue }}
                                      />
                                    )}
                                  </Field>
                                  <span className="text-danger">
                                    <ErrorMessage
                                      className="text-danger"
                                      name={`performanceShortfalls.${index}.initial_hearing_hcApproversFindingOnGuilt`}
                                    />
                                  </span>
                                </td>
                                <td className="position-relative" style={{ minWidth: '150px' }}>
                                  <span>
                                    <TableFormField
                                      type={'textarea'}
                                      placeholder={'Reason for deviation from ruling'}
                                      name={`incidents.${index}.reason_hc_deviation_motivation_ruling`}
                                      className="square-radius border-0 h-25 pt-4"
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage
                                        className="text-danger"
                                        name={`values.incidents.${index}.reason_hc_deviation_motivation_ruling`}
                                      />
                                    </span>
                                  </span>
                                </td>

                                <td className="position-relative" style={{ minWidth: '100px' }}>
                                  {!!item.initial_hearing_chairpersonsFindingOnGuilt?.length && (
                                    <TableFormField
                                      disabled={item.initial_hearing_chairpersonsFindingOnGuilt === 'NOT_GUILTY'}
                                      type={'select'}
                                      placeholder={'Select Sanction'}
                                      name={`incidents.${index}.initial_hearing_chairpersonsSanction`}
                                      selectOptions={getMisconductSanctionOptions(index)}
                                    />
                                  )}
                                  <span className="text-danger">
                                    <ErrorMessage
                                      className="text-danger"
                                      name={`incidents.${index}.initial_hearing_chairpersonsSanction`}
                                    />
                                  </span>
                                </td>

                                <td className="position-relative" style={{ minWidth: '100px' }}>
                                  <TableFormField
                                    // disabled={
                                    //   item.initial_hearing_hcApproversFindingOnGuilt ===
                                    //   item.initial_hearing_chairpersonsFindingOnGuilt
                                    // }
                                    type={'select'}
                                    placeholder={'Select Sanction'}
                                    name={`incidents.${index}.initial_hearing_hcApproversSanction`}
                                    selectOptions={getMisconductSanctionOptions(index)}
                                  />
                                  <span className="text-danger">
                                    <ErrorMessage
                                      className="text-danger"
                                      name={`incidents.${index}.initial_hearing_hcApproversSanction`}
                                    />
                                  </span>
                                </td>
                                <td className="position-relative" style={{ minWidth: '100px' }}>
                                  <span>
                                    <TableFormField
                                      type={'textarea'}
                                      placeholder={'Reason for deviation from sanction'}
                                      name={`incidents.${index}.reason_hc_deviation_motivation_sanction`}
                                      className="square-radius border-0 h-25 pt-4"
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage
                                        className="text-danger"
                                        name={`values.incidents.${index}.reason_hc_deviation_motivation_sanction`}
                                      />
                                    </span>
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </>
                      )}
                    </FieldArray>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default InitialHearingConfirmOverallSanctionForm;
