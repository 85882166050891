import React, { ReactElement, useContext, useState } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import { Col, Row } from 'reactstrap';
import { Tabs as GuidanceTab } from '../../../../../components/tabs/Tabs.component';
import Tabs from '../../../../../components/HearingAndAppealTabs/HearingAndAppealTabs.component';
import { HEARING_TABS } from '../../../../../utils/case-utils';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../../WorkflowContainer/workflow-utils';
import { WorkFlowFooter } from '../../../../../components/workflowFooter/WorkFlowFooter';
import { DocumentVersionControlTable } from '../../../../../components/documentVersionControlTable/DocumentVersionControlTable';
import { CaseType, TemplateType } from '../../../../../API';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../../../../components/taskContainer/TaskContainer';
import { getDocumentTemplateType, getFieldsAndValidationSchema } from '../../hearing-utils';
import InitialHearingCompileNoticeOfHearingForm from './InitialHearingCompileNoticeOfHearingForm';
import GuidanceModal from '../../../../../components/guidanceModals/GuidanceModal.component';
import './InitialHearingCompileNoticeOfHearing.scss';

const InitialHearingCompileNoticeOfHearing: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
  const setupValues = getFieldsAndValidationSchema(props.data.caseType, flowableFunctions.formKey);
  const { fields, validationSchema } = setupValues;
  const isAppeal = caseData && caseData.isAppeal;

  const getLetterTemplateType = (): TemplateType => {
    let itemKey;
    if (props.data.caseType === CaseType.MISCONDUCT) {
      itemKey = flowableFunctions.formKey;
    } else if (props.data.caseType === CaseType.POOR_PERFORMANCE) {
      itemKey = flowableFunctions.formKey + '-incapacity';
    }
    const templateType = getDocumentTemplateType(itemKey);
    if (!templateType) throw new Error('Template type is invalid');
    return templateType;
  };

  const initialValues = getFormDataFromCaseData(fields, caseData);

  const submitValues = (values: FormikValues): void => {
    const formValues = { ...values };
    if (props.data.caseType === CaseType.MISCONDUCT) {
      formValues.incidents = JSON.stringify(formValues.incidents);
    } else if (props.data.caseType === CaseType.POOR_PERFORMANCE) {
      formValues.performanceShortfalls = JSON.stringify(formValues.performanceShortfalls);
    }
    flowableFunctions.onNext(filterFormData(formValues, fields));
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500 mt-3">Hearing preparations</h4>
      <Row>
        <Col md={12}>
          <Tabs page={4} tabs={HEARING_TABS} />
        </Col>
      </Row>
      <h5 className="text-h5 text-blue text-capitalize font-weight-500 mt-3">Prepare Notice of Hearing</h5>
      <p style={{ color: 'red' }}>
        {' '}
        Important: Revisit the misconduct charges or performance shortfalls in the Notice of Hearing, including the
        narratives, and edit if needed to reflect the final allegations raised.
      </p>
      <GuidanceModal
        isOpen={modalIsOpen}
        setModal={setModalIsOpen}
        buttonTitle={`Notice of Hearing`}
        title={
          props.data.caseType === CaseType.POOR_PERFORMANCE
            ? 'Guidance: Incapacity Poor Performance Hearing'
            : 'Guidance: Disciplinary Hearing'
        }
      >
        {props.data.caseType === CaseType.POOR_PERFORMANCE ? (
          <>
            {!isAppeal ? (
              <>
                <p>
                  <div className="font-weight-bold text-dark">
                    What is the purpose of the Notice of an Incapacity Poor Performance Hearing?
                  </div>
                  <span className="text-muted">
                    The notice sets out the logistical details of the hearing (such as the date, time and venue), the
                    employee’s rights, and the allegations of continued and/or serious poor performance levelled against
                    the employee. It is a critical document in the hearing process
                    <br />
                    A key element of fairness is that the employee is timeously informed of the hearing and the
                    allegations against him/her. A notification of a hearing must be in writing and not given verbally.
                    This is to ensure that the employee understands the nature of the allegations as well as his/her
                    rights, and that proof of this exists
                    <br />
                    The purpose of this type of enquiry is to establish whether the employee is capable of achieving and
                    sustaining an acceptable standard of work for the role he/she was employe d for. Although the
                    employee is not “charged” in the same way as a disciplinary hearing, he/she is still entitled to
                    know the standard of performance required and how he/she allegedly failed in this regard. It is
                    therefore still advisable that the alleged performance shortcomings are stipulated in the notice. As
                    it is assumed that counselling, a review period(s) and/or a Performance Improvement Plan preceded
                    the hearing, he/she should have a good understanding of the allegations against him/her. The
                    employee is also entitled to the same procedural rights as in the case of a disciplinary hearing
                  </span>
                </p>
                <p>
                  <span className="text-muted">
                    <div className="font-weight-bold text-dark">
                      What is the minimum period of notice of hearing that must be given to an employee?
                    </div>
                    At least 2 working days’ notice of the incapacity hearing should be given to the employee to allow
                    him/her time to prepare his/her case. Depending on how complex the case is, a longer period may be
                    required to put the employee in a fair position to prepare. Should there be a reasonable request by
                    the employee for additional time in order to prepare, this should not be unreasonably withheld.
                    However, continuous requests for extensions of time, or delays that are unreasonable and
                    obstructive, should not be granted
                  </span>
                </p>
                <p>
                  <span className="text-muted">
                    <div className="font-weight-bold text-dark">
                      What are the rights of the employee prior to the hearing?
                    </div>
                    The employee must be timeously informed of the allegations against his/her, the right to have a
                    translator in the hearing, and the right to be represented in the hearing. He/she must be granted
                    sufficient time to prepare for the hearing. The employee must also be warned that if he/she does not
                    attend the hearing without providing a reasonable explanation, the employer will be entitled to
                    proceed with the hearing in their absence.
                  </span>
                </p>
                <p>
                  <span className="text-muted">
                    <div className="font-weight-bold text-dark">
                      What is the role of the Incapacity Poor Performance Policy in the drafting of charges?
                    </div>
                    The Incapacity Poor Performance Policy on LabourTeq (either provided as part of the system or
                    reflecting the employer’s own policy) provides mainly for the procedure to be followed when poor
                    performance problems are experienced
                  </span>
                </p>
                <p>
                  <span className="text-muted">
                    <div className="font-weight-bold text-dark">
                      Should the employee acknowledge receipt of the Notice of Hearing?{' '}
                    </div>
                    It is important that the employee confirms receipt of the Notice and that he/she is made aware that
                    this does not constitute an admission to the allegations made against him/her. It may happen that an
                    employee refuses to sign (manually or digitally ). The Notice can then be handed to the employee in
                    the presence of a witness. The witness will sign that the Notice was handed and read to the
                    employee, which will suffice as proof of the employee having received it. In th e hearing, the
                    chairperson will confirm at the outset whether or not the employee did indeed receive the Notice and
                    whether they received it timeously
                  </span>
                </p>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <GuidanceTab>
              {!isAppeal && (
                <>
                  <p>
                    <div className="font-weight-bold font-italic text-dark">
                      What is the purpose of the Notice of a Disciplinary Hearing?
                    </div>
                    <span className="text-muted">
                      The Notice sets out the logistical arrangements of the disciplinary hearing (such as the date,
                      time and venue), the employee’s rights, and the charges levelled against the employee. It is a
                      critical document in the disciplinary hearing process. A key element of fairness is that the
                      employee is timeously informed of the hearing and the allegations/charges against him/her. A
                      notification of a hearing must be in writing and not given verbally, in order to ensure that the
                      nature of the charges, as well as the employee's rights, are clearly understood by the employee
                      and that proof of this exists.
                    </span>
                  </p>
                  <br />
                  <p>
                    <span className="text-muted">
                      <div className="font-weight-bold font-italic text-dark">
                        What is the minimum period of notice of hearing that must be given to an employee?
                      </div>
                      At least 2 working days’ notice of the hearing should be given to the employee to allow him/her
                      time to prepare his/her case. Depending on how complex the case is, a longer period may be
                      required to put the employee in a fair position to prepare. Should there be a reasonable request
                      by the employee for additional time in order to prepare, this should not be unreasonably withheld.
                      However, continuous requests for extensions of time, or delays that are unreasonable and
                      obstructive, should not be granted.
                    </span>
                  </p>
                  <br />
                  <p>
                    <span className="text-muted">
                      <div className="font-weight-bold font-italic text-dark">
                        What are the rights of the employee prior to the hearing?
                      </div>
                      The employee must be timeously informed of the charges against them, their right to have a
                      translator in the hearing, and their right to be represented in the hearing. They must be granted
                      sufficient time to prepare for the hearing. The employee must also be warned that if they do not
                      attend the hearing without providing a reasonable explanation, the employer will be entitled to
                      proceed with the hearing in their absence.
                    </span>
                  </p>
                  <br />
                  <p>
                    <span className="text-muted">
                      <div className="font-weight-bold font-italic text-dark">
                        What is the role of the Disciplinary Code in the drafting of charges?
                      </div>
                      The Disciplinary Code on LabourTeq (either provided as part of the system or customised to reflect
                      the employer’s own Disciplinary Code) provides for an array of different transgressions and
                      proposed sanctions. It is a very crucial and necessary exercise to determine relevant charges with
                      reference to the transgressions contained in this Disciplinary Code, as that forms the basis for
                      the disciplinary action embarked upon and future disputes processes (if any). Careful
                      consideration of the available evidence is required when formulating the charges. The Code
                      provides a framework for consistent formulation of charges and sanctioning of employees committing
                      same or similar offences.{' '}
                    </span>
                  </p>
                  <br />
                  <p>
                    <div className="font-weight-bold font-italic text-dark">
                      What principles should be considered when drafting the charges brought against the employee as
                      part of the Notice of Disciplinary Hearing?
                    </div>
                    <ul className="px-3 text-muted">
                      <li>
                        • The charges must be specific enough for the employee to be able to answer them. You cannot
                        merely allege, for example, dishonesty. You will have to provide a summary of the incident or
                        event on which this allegation is based, including dates and/or times of the alleged
                        transgression, if appropriate.
                      </li>
                      <li>
                        • It normally will be sufficient if the employee has adequate information to ascertain what act
                        of misconduct he/she is alleged to have committed.
                      </li>
                      <li>
                        • Avoid so-called splitting of charges, whereby the employee is charged twice or even more for
                        the same offence.
                      </li>
                      <li>
                        • Ordinarily one cannot change the charge or add new charges after the commencement of the
                        hearing, where where it would be prejudicial for the employee to do so.
                      </li>
                    </ul>
                  </p>
                  <p>
                    <br />
                    <div className="font-weight-bold text-dark">
                      NB – On LabourTeq, charges will automatically pull through into the Notice of Disciplinary Hearing
                      as the information captured earlier in the employee case. You must apply your mind and edit these
                      charges if required, prior to issuing the employee with the Notice.
                    </div>
                    <br />
                  </p>
                  <br />
                  <p>
                    <span className="text-muted">
                      <div className="font-weight-bold font-italic text-dark">
                        What is the difference between theft and unauthorised possession/removal?
                      </div>
                      Theft can be defined as the unauthorised possession of or removal of company/fellow
                      employee/client property with the intention to deprive the owner permanently of it. It requires
                      proof of this latter intention and knowledge that the act was unlawful. Unauthorised possession or
                      removal is easier to prove as the intent to steal does not need to be proven. He/she must just not
                      have permission to have the employer’s property in their possession or to remove it, whatever the
                      motive may be. An element of dishonesty is still present.
                    </span>
                  </p>
                  <br />
                  <p>
                    <div className="font-weight-bold font-italic text-dark">
                      What is the difference between unauthorised absence, abscondment and desertion?
                    </div>
                    <span>
                      The employee must make his/her service available to the employer, the employer must agree/permit
                      any absence and the employee must communicate timeously in the event of unforeseen absence.
                    </span>
                    <span>
                      Unauthorised absence occurs when the employee is absent from work without permission at a time
                      when he/she is contractually obliged to render services and without any reasonable excuse for the
                      absence. Abscondment occurs when the employee is absent for so long a period that a reasonable
                      inference can be drawn that he/she does not intend to return to work. In the case of desertion,
                      the employee is also absent, and has actually expressed that he/she has no intent to return to
                      work.
                    </span>
                  </p>
                  <br />
                  <p>
                    <div className="font-weight-bold font-italic text-dark">
                      What is the difference between negligence and dereliction of duty?
                    </div>
                    <span className="text-muted">
                      The failure to comply with reasonable standards of care is classified as negligence. The employee
                      makes a mistake. This should be distinguished from wilful or intentional acts or omissions. The
                      standards against which you measure the employee must be reasonable and clear and known. Gross
                      negligence occurs when the employee is persistently negligent, or, if the particular act or
                      omission is particularly serious in itself. Dereliction of duty on the other hand refers to an
                      intentional or conscious failure of an employee to do his/her duty.
                    </span>
                  </p>
                  <br />
                  <p>
                    <div className="font-weight-bold font-italic text-dark">What is insubordination?</div>
                    <span className="text-muted">
                      There is a basic duty on the employee to obey reasonable, lawful instructions. Insubordination is
                      behaviour that goes beyond mere failure to comply with or obey instructions. The employee actually
                      challenges the authority of the employer and/or its manager and this must be proven. It includes
                      various forms of wilful disobedient behaviour. Gross insubordination could justify dismissal in
                      the first instance.
                    </span>
                  </p>
                  <br />
                  <p>
                    <div className="font-weight-bold font-italic text-dark">
                      Can I discipline someone for off-duty conduct?
                    </div>
                    <span className="text-muted">
                      Normally you can only act against misconduct in the workplace. What the employee does in his spare
                      time is his own business. However, where the private conduct of the employee has a causal link to
                      the workplace you will be able to act. For example, where the employee makes negative remarks
                      after hours at a party about the employer in front of a client which negatively impacts on the
                      employer’s reputation, action can be taken.
                    </span>
                  </p>
                  <br />
                  <p>
                    <div className="font-weight-bold font-italic text-dark">
                      Can the employee be found guilty of something he/she has not been charged with?
                    </div>
                    <span className="text-muted">
                      You would want your charges to be factually correct and align with the available evidence prior to
                      the hearing. You can still amend your charges before the employee has pleaded to them. In this
                      instance, you will issue a new Notice with revised charges, allowing the person once again
                      sufficient time to prepare which may require a postponement of the original hearing date.
                    </span>
                  </p>
                  <br />
                  <p>
                    <div className="font-weight-bold font-italic text-dark">
                      Can the employee be found guilty of something he/she has not been charged with?
                    </div>
                    <span className="text-muted">
                      You would want your charges to be factually correct and align with the available evidence prior to
                      the hearing. You can still amend your charges before the employee has pleaded to them. In this
                      instance, you will issue a new Notice with revised charges, allowing the person once again
                      sufficient time to prepare which may require a postponement of the original hearing date.
                    </span>
                  </p>
                  <p>
                    <span className="text-muted">
                      If the employer representative fails to prove a particular charge in the hearing, the employee
                      will typically be found not guilty. In limited circumstances the Courts have found that a person
                      can be found guilty of something with which he/she had not been charged with, if the alternative
                      constitutes a so-called “competent verdict” under the charge. Employers could thus in some
                      instances be excused for formulating disciplinary charges that are too narrow or incorrect. What
                      is of importance, though, is that the employee may not be prejudiced by this. Prejudice would
                      normally only arise where the employee does not know what case he or she is facing. If the
                      employee would not have conducted his/her defence any differently or come up with a different
                      defence if the charges had been differently formulated, no prejudice would have arisen. This can,
                      however, become a complicated legal dispute. Best is to ensure that you formulate the charges
                      upfront with the necessary care in line with available evidence, avoiding disputes of this nature.
                      Preparing one’s case properly, liaising with witnesses prior to the hearing and identifying
                      possible gaps in the employer’s case beforehand can help to avoid complications later. Using
                      alternative charges, can also be useful.
                    </span>
                  </p>
                  <br />
                  <p>
                    <div className="font-weight-bold font-italic text-dark">What is an alternative charge?</div>
                    <span className="text-muted">
                      You do not always know or cannot anticipate upfront what an employee’s defence would be to a
                      particular situation. You may want to state a main charge (which typically is more serious in
                      nature) and an alternative or fall-back charge, if the elements of the main charge were not
                      proven. This does not imply a shotgun approach and must still be well-thought through. For
                      example, you may charge an employee with fraudulent timekeeping. As an alternative, if deceitful
                      intent can not be proven, he/she is charged with negligently recording incorrect information on
                      his time sheet. In this way if found not guilty on the main charge, he can still be found guilty
                      on the alternative if he made mistakes on his time sheet.
                    </span>
                  </p>
                  <br />
                  <p>
                    <div className="font-weight-bold font-italic text-dark">
                      Why should I avoid an overlap between misconduct and criminal charges?
                    </div>
                    <span className="text-muted">
                      Often employers use criminal terms such as “theft” or “fraud” in an attempt to amplify the
                      seriousness of the alleged misconduct and/or to justify dismissal as an appropriate sanction.
                      Complex, criminal terms, however, are best to be avoided in the workplace as they require proof of
                      the different elements of that crime. Fraud, for example, requires an unlawful, intentional
                      misrepresentation that prejudices or has the potential to prejudice another. If the employer fails
                      to prove the required intent, the employee will be found not guilty in the hearing. A charge of
                      dishonesty could rather suffice in the workplace. Simple charges focusing on the employee’s
                      obligations as contained in their employment contract, the employer’s disciplinary code, standing
                      work procedure or a work policy are best.
                    </span>
                  </p>
                  <br />
                  <p>
                    <div className="font-weight-bold font-italic text-dark">
                      Should the employee acknowledge receipt of the Notice of Hearing?
                    </div>
                    <span className="text-muted">
                      It is important that the employee who receives the disciplinary hearing notification confirms
                      receipt and that they are made aware that this does not constitute an admission to the allegations
                      made against them. It may, however, happen that an employee refuses to sign (manually or
                      electronically). The Notice can then be handed to them in the presence of a witness. The witness
                      will sign that the document was handed and read to them, which will suffice as proof of them
                      having been served with the Notice. In the hearing, the chairperson will confirm at commencement
                      whether or not the person did indeed receive the Notice and whether they received it timeously.
                    </span>
                  </p>
                </>
              )}
            </GuidanceTab>
          </>
        )}
      </GuidanceModal>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={submitValues}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
          <>
            <InitialHearingCompileNoticeOfHearingForm data={props.data} />
            <button
              className="btn prepare-letter-button mb-3"
              onClick={(): void => {
                props.data.flowableFunctions.onOpenNewDocument(values, fields, getLetterTemplateType());
              }}
              disabled={readOnly}
            >
              PREPARE NOTICE OF {props.data.caseType === CaseType.POOR_PERFORMANCE && 'INCAPACITY'} HEARING
            </button>
            {props.data.processInstanceId ? (
              <DocumentVersionControlTable
                data={props.data}
                processInstanceId={props.data.processInstanceId}
                templateType={getLetterTemplateType()!}
                completeTask={props.data.flowableFunctions.completeTask}
              />
            ) : null}
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default InitialHearingCompileNoticeOfHearing;
