import React, {useCallback, useContext, useEffect, useState} from 'react';
import TopBarComponent from '../../../components/topBar/TopBar.component';
import { Link } from 'react-router-dom';
import ButtonWithIcons from '../../../components/buttons/ButtonWIthIcons.component';
import { PlusIcon } from '../../../components/icon/Icon.component';
import Loader from '../../../components/loader/Loader';
import { Card, Col, Row } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';
import DynamicTableLinkable, { ColumnHeadingAction, RowData } from '../../Employee/ViewEmployees/DynamicTableLinkable';
import { list, mutate } from '../../../utils/graphql-utils';
import { listOrganisations, listUsers } from '../../../graphql/queries';
import { AuditLogEventType, Organisation, UserRole } from '../../../API';
import { useErrorHandler } from '../../../utils/notification-utils';
import { useHistory } from 'react-router';
import { UserContext, UserContextProps } from '../../../App';
import Popup from '../../../components/Popup/Popup.component';
import { disableCognitoUser, getCognitoUser } from '../../../utils/cognito-utils';
import { createLog } from '../../../utils/audit-log-utils';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { User } from '../../../models';
import { updateUserMutation } from '../../../graphql-custom/custom-mutations';
import { toTitleCase } from '../../../utils/string-utils';
import AddEmployeeButton from '../../../components/AddEmployeeButton/AddEmployeeButton';

interface UsersAndStatuses extends User {
  userStatus: string | undefined;
  organisationName?: string | undefined;
}

interface UsersState {
  data?: UsersAndStatuses[];
  loading: boolean;
}

const Users: React.FC = () => {
  const [state, setState] = useState<UsersState>({ data: [], loading: true });
  const [, setOrganisations] = useState<Organisation[]>([]);

  const handleError = useErrorHandler();
  const history = useHistory();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const impersonateUser = useContext<Partial<UserContextProps>>(UserContext).impersonateUser;

  const defaultColumnActions = [
    {
      label: 'edit',
      func: (user: RowData): void => {
        history.push(`/create-user/${user.id}`);
      },
    },
    {
      label: 'delete',
      func: (user: RowData): void => {
        disableUser((user as unknown) as User);
      },
    },
    {
      label: 'reset',
      func: async (user: RowData): Promise<void> => {
        const answer = await Popup.confirm(
          'Reset Login Attempts',
          `Are you sure you want to re-assign access to this user? You cannot reverse this action.`,
        );
        if (answer) {
          const variables = {
            id: user.id,
            loginAttempts: 0,
          };
          mutate(updateUserMutation, variables)
            .then(async () => {
              if (currentUser) {
                createLog(currentUser, AuditLogEventType.USER_EDITED).catch(error => handleError(error));
              }
              if (currentUser && currentUser.organisationId) getUsers(currentUser.organisationId);
            })
            .catch(error => handleError(error));
        }
        return;
      },
    },
  ];

  const columnActions = currentUser?.userRoles?.includes(UserRole.SUPER_ADMIN)
    ? [
        ...defaultColumnActions,
        {
          label: 'impersonate',
          func: async (user: RowData): Promise<void> => {
            if (impersonateUser && currentUser?.id && user.id && typeof user.id === 'string') {
              await impersonateUser(currentUser.id, user.id).then(() => history.push('/'));
            } else {
              throw new Error('invalid impersonation user');
            }
          },
        },
      ]
    : defaultColumnActions;

  const columns = [
    { key: 'firstName', label: 'First Name' },
    { key: 'lastName', label: 'Last Name' },
    { key: 'rolesString', label: 'User Role' },
    { key: 'organisationName', label: 'organisation' },
    { key: 'userStatus', label: 'Status' },
    { actions: columnActions, label: 'actions', type: 'action' } as ColumnHeadingAction,
  ];

  const getUserStatus = (status?: string): string => {
    if (status === 'FORCE_CHANGE_PASSWORD') {
      return 'Invited';
    } else if (status === 'CONFIRMED') return 'Confirmed';
    else return '';
  };

  const getUsers = useCallback((organisationId: string): void => {
    setState(oldState => ({ ...oldState, loading: true }));
    const variables = currentUser?.userRoles?.includes(UserRole.SUPER_ADMIN)
      ? { filter: { active: { eq: true } }, limit: 10000 }
      : { filter: { active: { eq: true }, organisationId: { eq: organisationId } }, limit: 10000 };
    list(listUsers, variables)
      .then(res => {
        if (res.data && (res.data as { [key: string]: any }).listUsers) {
          const data: User[] = (res.data as { [key: string]: any }).listUsers.items;
          data.forEach((item: User) => {
            if (item.roles) {
              // @ts-ignore
              item['rolesString'] = item.roles.map(item => toTitleCase(item, '_')).join(', ');
            }
          });
          const promises: Promise<CognitoIdentityServiceProvider.Types.AdminGetUserResponse>[] = data.map(
            (item: User) => {
              return getCognitoUser(item.cognitoSub);
            },
          );
          Promise.all(promises).then((res: CognitoIdentityServiceProvider.Types.AdminGetUserResponse[]) => {
            const usersAndStatuses: UsersAndStatuses[] = data.map((dbUser: User) => {
              const matchingCognitoUser = res.find(
                (item: CognitoIdentityServiceProvider.Types.AdminGetUserResponse) => {
                  return item.Username === dbUser.cognitoSub;
                },
              );
              return { ...dbUser, userStatus: getUserStatus(matchingCognitoUser?.UserStatus) };
            });
            setState(oldState => ({ ...oldState, data: usersAndStatuses, loading: false }));
          });
        }
      })
      .catch(error => handleError(error));
  }, []);

  const disableUser = async (user: User): Promise<void> => {
    if (currentUser && currentUser.id === user.id) {
      handleError(new Error('You cannot deactivate yourself'));
      return;
    }
    const answer = await Popup.confirm(
      'Deactivate User',
      `Are you sure you want to deactivate this user? You cannot reverse this action.`,
    );
    if (!answer) {
      return;
    } else {
      await disableCognitoUser(user.cognitoSub)
        .then(async () => {
          if (currentUser) {
            createLog(currentUser, AuditLogEventType.USER_EDITED).catch(error => handleError(error));
          }
          const variables = {
            id: user.id,
            active: false,
          };

          mutate(updateUserMutation, variables)
            .then(async () => {
              if (currentUser) {
                createLog(currentUser, AuditLogEventType.USER_EDITED).catch(error => handleError(error));
              }
              if (currentUser && currentUser.organisationId) getUsers(currentUser.organisationId);
            })
            .catch(error => handleError(error));
        })
        .catch(error => {
          handleError(error);
        });
      return;
    }
  };

  useEffect(() => {
    if (currentUser && currentUser.organisationId) {
      getUsers(currentUser.organisationId);
    }

    list(listOrganisations)
      .then(res => {
        // WHY CASTING AS ANY!!!???
        if (res.data && (res.data as any).listOrganisations) {
          const data = (res.data as any).listOrganisations.items;
          setOrganisations(data);
        }
      })
      .catch(error => handleError(error));
  }, [currentUser, handleError, getUsers]);

  return (
    <>
      <TopBarComponent title={'Users'} subTitle={'View Users'}>
        <AddEmployeeButton />
        <Link to="/create-user">
          <ButtonWithIcons title={'User'} leftIcon={<PlusIcon />} buttonType={'btn-bd-purple'} />
        </Link>
      </TopBarComponent>
      <div className="content">
        {state.loading ? (
          <div className="d-flex justify-content-center mt-5">
            <Loader />
          </div>
        ) : (
          <>
            <h4 className="text-default text-capitalize font-weight-bold mt-3 ml-3">Users</h4>
            <Row>
              <Col className="mb-5" md="12">
                <Card>
                  <CardBody>
                    <DynamicTableLinkable
                      linkPrefix={'create-user'}
                      columns={columns}
                      data={(state.data as unknown) as RowData[]}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default Users;
