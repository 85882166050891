/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://uwv3vnqme5hfjmr3rbgj2xzueu.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-z656jzkdnfbx3mqfm5pvuufmc4",
    "aws_cloud_logic_custom": [
        {
            "name": "middlewareREST",
            "endpoint": "https://bq80y92czd.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        },
        {
            "name": "notificationsApi",
            "endpoint": "https://5xgcfddil8.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:6e10bf7b-96c2-4450-9e8e-d66ad491725a",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_AlxAjGcJU",
    "aws_user_pools_web_client_id": "7aglban1c46m28tkopvckt4iq8",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "labouronnect-20200228115848-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "http://labouronnect-20200228115848-hostingbucket-dev.s3-website.eu-central-1.amazonaws.com",
    "aws_user_files_s3_bucket": "labourtech-case-documents100929-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
