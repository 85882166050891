import React, { ReactElement } from 'react';
import { Col, Row } from 'reactstrap';
import Tabs from '../../../../../components/HearingAndAppealTabs/HearingAndAppealTabs.component';
import { HEARING_TABS } from '../../../../../utils/case-utils';
import { Formik, FormikErrors, FormikProps, FormikValues, getIn } from 'formik';
import {
  CaseData,
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../../WorkflowContainer/workflow-utils';
import { WorkFlowFooter } from '../../../../../components/workflowFooter/WorkFlowFooter';
import { CaseType } from '../../../../../API';
import { Tooltip } from '../../../../../components/tooltips/Tooltip.component';
import { getFieldsAndValidationSchema } from '../../hearing-utils';
import InitialHearingConfirmOverallSanctionForm from './InitialHearingConfirmOverallSanctionForm';
import { ObjectSchema } from 'yup';

export const InitialHearingConfirmOverallSanction: React.FC<WorkflowComponentProps> = (
  props: WorkflowComponentProps,
) => {
  const { caseData, flowableFunctions } = props.data;
  const setupValues = getFieldsAndValidationSchema(props.data.caseType, props.data.flowableFunctions.formKey);
  const { fields, validationSchema } = setupValues;
  const initialValues = getFormDataFromCaseData(fields, caseData);

  const submitValues = (values: FormikValues): void => {
    const formValues = { ...values };
    if (props.data.caseType === CaseType.MISCONDUCT) {
      formValues.incidents = JSON.stringify(values.incidents);
    } else if (props.data.caseType === CaseType.POOR_PERFORMANCE) {
      formValues.performanceShortfalls = JSON.stringify(values.performanceShortfalls);
    }

    flowableFunctions.onNext(filterFormData(formValues, fields), values.overallSanction === '');
  };

  const setErrors = (
    errors: FormikErrors<FormikValues>,
    setFieldTouched: (field: string, touched: boolean) => void,
  ): void => {
    const errorKeys = Object.keys(errors);
    errorKeys.forEach(field => {
      //if fieldArray
      if (Array.isArray(errors[field])) {
        const err = getIn(errors, field);
        const innerArr = Object.keys(err);
        innerArr.forEach((item, index) => {
          let fieldNames;
          if (err[item]) {
            fieldNames = Object.keys(err[item]);
            fieldNames.forEach(name => setFieldTouched(`${field}.${index}.${name}`, true));
          }
        });
      } else {
        setFieldTouched(field, true);
      }
    });
  };

  const setUpValues: {
    fields: { [key: string]: string };
    validationSchema: ObjectSchema<object>;
  } = getFieldsAndValidationSchema(props.data.caseType, flowableFunctions.formKey);

  const getFormValuesForSubmission = (values: FormikValues): { [key: string]: any } => {
    const formValues: Partial<CaseData> = values;
    const setupValuesWithoutIncidents = { ...setUpValues.fields };
    delete setupValuesWithoutIncidents.incidents;

    return filterFormData(formValues, setupValuesWithoutIncidents);
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500 mt-3">Conclude Hearing</h4>
      <Row>
        <Col md={12}>
          <Tabs page={8} tabs={HEARING_TABS} />
        </Col>
      </Row>
      <h5 className="text-h5 text-blue font-weight-500 mt-3">
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Chairperson's Ruling
        <span className="ml-3">
          <Tooltip
            title={"Chairperson's Ruling"}
            id="initial_hearing_chairpersonsRuling"
            text={`Based on the Chairperson’s ruling, capture below the findings on guilt and sanction as applicable.`}
          />
        </span>
      </h5>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={submitValues}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit, errors, setFieldTouched }: FormikProps<FormikValues>): ReactElement => (
          <>
            <InitialHearingConfirmOverallSanctionForm
              getFormValuesForSubmission={getFormValuesForSubmission}
              data={props.data}
            />
            <WorkFlowFooter
              data={props.data}
              finalPage={values.overallSanction === 'NOT_APPLICABLE'}
              onNext={(): void => {
                setErrors(errors, setFieldTouched);
                if (!Object.keys(errors).length) handleSubmit();
              }}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
