import React, { useContext, useState } from 'react';
import './PrepareSuspensionLetter.styles.scss';
import { TemplateType } from '../../../API';
import { Input, InputGroup, Label } from 'reactstrap';
import { getFormDataFromCaseData, WorkflowComponentProps } from '../../WorkflowContainer/workflow-utils';
import { DocumentVersionControlTable } from '../../../components/documentVersionControlTable/DocumentVersionControlTable';
import { WorkFlowFooter } from '../../../components/workflowFooter/WorkFlowFooter';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../../components/taskContainer/TaskContainer';
import { FormikValues } from 'formik';
import { updateProcessInstanceVariables } from '../../../utils/flowable/flowable-utils';
import { useErrorHandler } from '../../../utils/notification-utils';
import GuidanceModal from '../../../components/guidanceModals/GuidanceModal.component';
import { EmailType } from '../../../utils/email-utils';

export const PrepareSuspensionLetter: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
  const { caseData } = props.data;
  const fields = {
    hasReadSuspensionLetterGuidance: 'boolean',
  };
  const handleError = useErrorHandler();

  const initialValues: FormikValues = getFormDataFromCaseData(fields, caseData, props.data.caseData.isAppeal);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userHasReadSuspensionLetterGuidance, setUserHasReadSuspensionLetterGuidance] = useState(
    initialValues.hasReadSuspensionLetterGuidance,
  );

  const updateUserHasReadGuidance = (): void => {
    if (!userHasReadSuspensionLetterGuidance) {
      setUserHasReadSuspensionLetterGuidance(true);
      // calling flowable directly to avoid page reload
      updateProcessInstanceVariables(props.data.processInstanceId, [
        { name: 'hasReadSuspensionLetterGuidance', value: true, type: 'boolean' },
      ]).catch(error => handleError(error));
    }
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">Prepare suspension letter</h4>
      <GuidanceModal
        isOpen={modalIsOpen}
        setModal={setModalIsOpen}
        buttonTitle={'Preparing a Suspension Letter'}
        title="Guidance: Preparing a Suspension Letter"
        onClickCallback={updateUserHasReadGuidance}
      >
        <p>
          <div className="font-weight-bold text-dark">What content should appear in the Letter of Suspension? </div>
          <span className="text-muted">
            You merely want to indicate the alleged transgression and <span className="text-underline">briefly</span>{' '}
            summarise what it relates to. Formal charges do not need to be formulated or provided at this time.
            Potential witnesses do not need to be disclosed. All the case information on LabourTeq does{' '}
            <span className="text-underline">not</span> need to be included.
          </span>
        </p>
        <br />
        <p>
          <span className="text-muted">
            You should thus <span className="text-underline">formulate a concise summary of the allegations</span> in
            the space provided, for inclusion into the Letter of Suspension. For example, “Allegations of serious
            non-compliance with standing operating procedures have arisen whereby you incorrectly captured and/or
            authorised payments to service providers resulting in monetary loss being suffered by the employer - which
            is currently the subject of an investigation”.
          </span>
        </p>
        <br />
        <p>
          <span className="text-muted">
            Depending on when the Letter is issued, it will indicate that it is a suspension pending an investigation
            and the consequences thereof, alternatively, a suspension pending a disciplinary hearing. Full details of
            the latter will be provided in the Notice of a Disciplinary Hearing, including final charges separate from
            what was stipulated initially in your Letter of Suspension. In the example above, you may later realise that
            the payments made to service providers were as a result of fraudulent activities and rather formulate your
            charge as such.
          </span>
        </p>
        <br />
        <p>
          <span className="text-muted">
            The Letter further contains practical arrangements, like the fact that the person may not contact fellow
            employees or clients without employer permission and come onto the premises without being escorted. He/she
            is remunerated whilst on suspension.
          </span>
        </p>
      </GuidanceModal>
      <Label for="firstName" className="text-default py-3">
        Summary of Allegations
      </Label>
      {props.data.caseData.incidents &&
        props.data.caseData.incidents.map((incident, index) => (
          <InputGroup className="allegations-summary mt-sm-1 mb-0 no-border mb-2 w-100" key={index}>
            <Input
              autoComplete="off"
              className="bg-white h-50 w-100 mb-3"
              value={incident.summaryOfFacts}
              type="textarea"
              style={{ height: '100%', minHeight: '10vh', minWidth: '65vw', border: '1px solid lightgrey' }}
              disabled={true}
            />
          </InputGroup>
        ))}

      <p className="text-danger">
        Please reference internal policies to determine if Suspension decisions and letters should be sent 
        for HC or Executive approval, before issuing.
      </p>
      <p className="text-danger">
        Please note you will not be able to proceed before reading the guidelines of how to add the summary of
        allegations above.
      </p>
      <button
        className="btn prepare-letter-button mt-3 mb-3"
        onClick={(): void => {
          props.data.flowableFunctions.onOpenNewDocument(
            {
              hasReadSuspensionLetterGuidance: userHasReadSuspensionLetterGuidance,
            },
            fields,
            TemplateType.SUSPENSION_PENDING_INVESTIGATION_LETTER,
          );
        }}
        disabled={readOnly || !userHasReadSuspensionLetterGuidance}
      >
        PREPARE LETTER
      </button>
      {props.data.processInstanceId ? (
        <DocumentVersionControlTable
          data={props.data}
          processInstanceId={props.data.processInstanceId}
          templateType={TemplateType.SUSPENSION_PENDING_INVESTIGATION_LETTER}
          completeTask={props.data.flowableFunctions.completeTask}
          customEmailTypeForIssueDocumentMail={EmailType.ISSUE_DOCUMENT_SUSPENSION_LETTER}
        />
      ) : null}
      <WorkFlowFooter
        data={props.data}
        onNext={(): void => props.data.flowableFunctions.onNext()}
        onCancel={(): void => console.log('cancel')}
        onSaveAndClose={(): void => {
          props.data.flowableFunctions.onSaveAndClose();
        }}
        disabled={!userHasReadSuspensionLetterGuidance}
      />
    </div>
  );
};
