import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import TopBarComponent from '../../components/topBar/TopBar.component';
import { CaseStatusBar } from '../../components/caseStatusBar/caseStatusBar';
import { queryHistoricTasksByMasterProcessInstanceIdAndFinished } from '../../utils/flowable/flowable-utils';
import { FlowableHistoricTask, FlowableVariable } from '../../utils/flowable/flowable-types';
import CaseHistoryAccordion from './caseHistoryAccordion/CaseHistoryAccordion';
import CaseHistoryData from './CaseHistoryData';
import moment from 'moment';

const CaseHistoryContainer: React.FC = () => {
  const { masterProcessInstanceId } = useParams<{ masterProcessInstanceId: string }>();
  const [tasksWithFormKeys, setTaskWithFormKeys] = useState<FlowableHistoricTask[]>([]);

  const duplicateFormKey = (withFormKeys: FlowableHistoricTask[], formKey: string): boolean => {
    return withFormKeys.some(element => {
      return element.formKey === formKey;
    });
  };

  const isABackTask = (flowableVars: FlowableVariable[]): boolean => {
    const pathVarNames = [
      'path',
      'investigatorPath',
      'chairpersonPath',
      'employerRepresentativePath',
      'HCApproverPath',
    ];
    for (const varName of pathVarNames) {
      const pathVar = flowableVars.find(v => v.name === varName && v.scope === 'local');
      if (pathVar?.value === 'back') {
        return true;
      }
    }
    return false;
  };

  const shouldShowTask = useCallback((task: FlowableHistoricTask): boolean => {
    return task.formKey !== 'case-history' && task.deleteReason === null && !isABackTask(task.variables);
  }, []);

  const loadCase = useCallback(
    async (masterProcessInstanceId: string): Promise<void> => {
      const historicCaseTasks = await queryHistoricTasksByMasterProcessInstanceIdAndFinished(masterProcessInstanceId);
      const withFormKeys: FlowableHistoricTask[] = [];
      historicCaseTasks.forEach(task => {
        if (!!task.formKey && shouldShowTask(task)) {
          // pick the most recent task, for duplicates.
          const duplicateKeys = duplicateFormKey(withFormKeys, task.formKey);
          if (duplicateKeys) {
            const index = withFormKeys.findIndex(element => element.formKey === task.formKey);
            if (index >= 0 && withFormKeys[index].endTime && task.endTime) {
              if (moment(withFormKeys[index].endTime).isBefore(task.endTime)) withFormKeys[index] = task;
            }
          } else {
            withFormKeys.push(task);
          }
        }
      });

      const sortedTasks = withFormKeys.sort((a, b) => {
        return moment(a.endTime).diff(moment(b.endTime));
      });

      setTaskWithFormKeys(sortedTasks);
    },
    [setTaskWithFormKeys, shouldShowTask],
  );

  useEffect(() => {
    if (masterProcessInstanceId) {
      loadCase(masterProcessInstanceId).catch();
    }
  }, [masterProcessInstanceId, loadCase]);

  return (
    <>
      <TopBarComponent title={'case history'} hideSearch={true} />
      <CaseStatusBar status="Case History" />
      <div className="workflow-content">
        <div className="task-container mt-5">
          {tasksWithFormKeys.map((task, index) => (
            <CaseHistoryAccordion task={task} index={index} key={index}>
              <CaseHistoryData task={task} />
            </CaseHistoryAccordion>
          ))}
        </div>
      </div>
    </>
  );
};

export default CaseHistoryContainer;
